import React from "react";
import "./Stats.css";

import StatsOverviewIcon from "../assets/stats-overview.svg";
import DetailSection from "./components/DetailSection";
import Table from "./components/Table";

const alpTableTitles = [
  'Token',
  'Price',
  'Pool',
  'Weight',
  'Utilization',
]

const mockData = [
  ['ALP', '$22905.99', '$1,234,567', '43.88%', '43.88%'],
  ['BTC', '$22905.99', '$1,234,567', '43.88%', '43.88%'],
  ['ETH', '$22905.99', '$1,234,567', '43.88%', '43.88%'],
  ['USDC', '$22905.99', '$1,234,567', '43.88%', '43.88%'],
  ['USDT', '$22905.99', '$1,234,567', '43.88%', '43.88%'],
]

const Stats = () => {
  const priceNode = <div className='flex-row justify-start align-center app-trade-price-node'>
    <span className='detail-section-content' style={{ color: '#0ECA82' }}>$22905.99</span>
    <div style={{ background: '#0ECA82' }}>+3.99%</div>
  </div>

  return (
    <div className="app-stats section-container">
      <div className='app-stats-overview app-card-white flex-row justify-between align-center'>
        <div className="flex-row justify-start align-center">
          <img src={StatsOverviewIcon} alt='' />
          Overview
        </div>
        <DetailSection title='Total Volume' content='$1,234,567' />
        <DetailSection title='Total Fees' content='1,234,567 ALP' />
        <DetailSection title='ALP Pool' content='$1,234,567' />
      </div>
      <div className='app-stats-major flex-row justify-between align-start'>
        <div className='app-stats-trading app-card-white'>
          <div className="flex-row justify-start align-center">
            <img src={StatsOverviewIcon} alt='' />
            Trading
          </div>
          <DetailSection title='24H Volume' content='$1,234,567' />
          <div className="flex-row justify-between align-center">
            <DetailSection title='Open Interest (L)' content='$1,234,567' />
            <DetailSection title='Open Interest (S)' content='$1,234,567' />
          </div>
        </div>
        <div className='app-stats-alp app-card-white'>
          <div className="flex-row justify-start align-center">
            <img src={StatsOverviewIcon} alt='' />
            ALP Token
          </div>
          <DetailSection title='Price' node={priceNode} />
          <div className="flex-row justify-between align-center">
            <DetailSection title='Market Cap' content='$1,234,567' />
            <DetailSection title='Stablecoin Percentag' content='43.88%' />
          </div>
        </div>
      </div>
      <div className='app-stats-subtitle'>
        ALP Composition
      </div>
      <Table titles={alpTableTitles} rows={mockData} />
    </div>
  );
};

export default Stats;