import React, { useState } from 'react';
import Modal from 'react-modal';
import './PositionClose.css';
import { IConsts, IPositionInfo } from 'abex-ts-sdk';
import ModalHeader from './ModalHeader';
import InfoList from './InfoList';
import ConfirmClose from '../../assets/confirm-close.svg';
import Button from './Button';
import { executeApiCall, formatNumber, localSymbolToOriginal, priceToString } from '../utils';
import { WalletContextState } from '@suiet/wallet-kit';
import TokenInput from './TokenInput';
import TextSwitch from "./TextSwitch"
import PendingOrderSettings from './PendingOrderSettings'
import { LONG_COLOR, RELAYER_FEE, SHORT_COLOR, TOKENS } from '../constants';
import { DisplayPosition } from '../models';
import { ValueChanger } from './ValueChanger';
import { calculatePNL } from '../math';
import { usePositionConfig } from '../../hooks/usePositionConfig';
import { set } from 'superstruct';

type Props = {
  position: DisplayPosition | null;
  showPositionClose: boolean;
  setShowPositionClose: (show: boolean) => void;
  wallet: WalletContextState;
  network: string;
  refreshAccount: () => void;
  refreshPosition: () => void;
  consts: IConsts;
  markPrice: number;
  refreshVaultInfo: () => void;
  refreshReservingRate: () => void;
  closeBps: number;
  collateralToken: string;
  collateralPrice: number;
  refreshOrder: () => void;
  //prices: { [key: string]: number };
  //positionConfigMap: { [key: string]: IPositionConfig };
}

const PositionClose: React.FC<Props> = ({
  position,
  showPositionClose,
  setShowPositionClose,
  wallet,
  network,
  refreshAccount,
  refreshPosition,
  consts,
  markPrice,
  refreshVaultInfo,
  refreshReservingRate,
  closeBps,
  collateralToken,
  collateralPrice,
  //prices,
  //positionConfigMap,
  refreshOrder,
}) => {
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [getCloseValue, setCloseValue] = useState<number | null>(null);
  const [amount, setAmount] = useState<number | null>(null);
  const [mode, setMode] = useState<"Market" | "Limit">("Market");
  const [getSlippageValue, setSlippageValue] = useState<Boolean>(false)
  const [getLeverageValue, setLeverageValue] = useState<Boolean>(false)
  const [indexPrice, setIndexPrice] = useState<number | null>(null);
  const { positionConfigMap } = usePositionConfig(network, consts)
  //const {delta} = calculatePNL(position as IPositionInfo, prices, positionConfigMap);

  if (!position) {
    return null;
  }

  const closeFee = closeBps * (amount || 0) * markPrice / collateralPrice

  let {pnlValue} = calculatePNL(position as IPositionInfo, {[collateralToken]: collateralPrice, [position.indexToken]: (indexPrice || markPrice)}, positionConfigMap, consts)
  pnlValue = pnlValue * (amount || 0) / position.indexAmountDisplay - closeFee * collateralPrice

  let percent = 0

  if (amount) {
    percent = Math.min(amount / position.indexAmountDisplay, 1)
  }

  const checkVaild = () => {
    if (!amount) {
      return 'Invalid Close Amount'
    }
    return false
  }
  const invalid = checkVaild()

  const clearStates = () => {
    setAmount(null);
    setIndexPrice(null);
  }

  const decreasePosition = () => {
    if (!position) {
      return;
    }
    setIsSubmitLoading(true);
    executeApiCall(
      async (api) => {
        if (!amount || (mode === 'Limit' && !indexPrice)) {
          throw new Error('Invalid input');
        }
        const amountToDecrease = BigInt(((amount || 0) * (10 ** consts.coins[position.indexToken].decimals)).toFixed(0));
        const tx = await api.decreasePosition(
          position.id,
          position.collateralToken,
          position.indexToken,
          amountToDecrease,
          position.long,
          mode === 'Market' ? markPrice : (indexPrice || 0),
          collateralPrice,
          mode === 'Limit',
          (!position.long && ((indexPrice || 0) < markPrice)) || (position.long && ((indexPrice || 0) > markPrice)),
          false,  // FIXME: hardcode
          0.003,  // FIXME: hardcode
          mode === 'Limit' ? BigInt(RELAYER_FEE * 1e9) : BigInt(1),
        );
        if (amountToDecrease === BigInt(position.positionAmount) && mode === 'Market') {
          await api.clearClosedPosition(position.id, position.collateralToken, position.indexToken, position.long, tx);
        }
        return tx
      },
      (res) => {
        clearStates();
        return `Success send tx: ${res?.digest}`;
      },
      (e) => `Error: ${e.message}`,
      wallet,
      network,
      [refreshAccount, refreshPosition, refreshVaultInfo, refreshReservingRate, refreshOrder, () => setShowPositionClose(false)],
    ).finally(() => {
      setIsSubmitLoading(false);
    });
  }

  const handleClose = () => {
    if (!amount || invalid || !position) {
      return;
    }
    decreasePosition();
  }

  if (!position) {
    return null;
  }

  const listContent: [string|JSX.Element, string|number|JSX.Element, string?][] = [
    // ['Allowed Slippage', '0.03%'],
    // ['Market Price', '0.03%'],
    [<span style={{color: pnlValue >= 0? LONG_COLOR: SHORT_COLOR}}>Estimated {pnlValue >= 0 ? 'Profit' : 'Loss'}</span>, <span style={{color: pnlValue >= 0? LONG_COLOR: SHORT_COLOR}}>${formatNumber(Math.abs(pnlValue))}</span>],
    ['Mark Price', '$' + priceToString(markPrice)],
    ['Entry Price', '$' + priceToString(position.entryPrice)],
    ['Liq Price', '$' + priceToString(position.liquidationPrice)],
    ['Size', <ValueChanger originalValue={`$${formatNumber(position.positionSize)}`} newValue={percent ? `$${formatNumber(position.positionSize * (1 - percent))}` : undefined} />],
    [`Collateral (${localSymbolToOriginal(position.collateralToken)})`, <ValueChanger originalValue={formatNumber(position.collateralAmountDisplay)} newValue={percent? `${formatNumber(position.collateralAmountDisplay * (1 - percent))}`: undefined} />],
    // ['PnL', <ValueChanger originalValue={`${position.pnl > 0 ? '+' : '-'}$${formatNumber(Math.abs(position.pnl))}`} newValue={percent ? `${position.pnl > 0 ? '+' : '-'}$${formatNumber(Math.abs(position.pnl * (1 - percent)))}` : undefined} />],
    ['Close Fee', `${formatNumber(closeFee)} ${localSymbolToOriginal(collateralToken)} ($${formatNumber(collateralPrice * closeFee)})`],
    // ['Receive', '45,555.22 ETH($50,102,000)']
  ]
  if (mode === 'Limit') {
    listContent.push(['Relayer Fee', `${RELAYER_FEE} SUI`])
  }

  return (
    <Modal
      className='app-pos-close-main modal-animation'
      isOpen={showPositionClose}
      onRequestClose={() => {
        clearStates()
        setShowPositionClose(false)
      }}
      shouldCloseOnOverlayClick={true}
    >
      <div className='app-pos-close-card'>
        <ModalHeader
          icon={ConfirmClose}
          text={`${localSymbolToOriginal(position.indexToken)}/USD ${position.long ? 'Long' : 'Short'}`}
          setShowModal={() => setShowPositionClose(false)}
        />
        <div className='app-trade-action-tab flex justify-between'>
          <div
            onClick={() => {
              setMode('Market')
              setIndexPrice(null)
            }}
            className={`app-trade-action-tab-item ${mode === 'Market' ? 'app-trade-action-tab-item-active color-black-text' : ''}`}
          >Market</div>
          <div
            onClick={() => {
              setMode('Limit')
              setIndexPrice(null)
            }}
            className={`app-trade-action-tab-item ${mode === 'Limit' ? 'app-trade-action-tab-item-active color-black-text' : ''}`}
          >Trigger</div>
        </div>
        <TokenInput
          token={TOKENS.filter(e => e.symbol === position.indexToken)[0]}
          titlePrefix={'Close'}
          amount={amount}
          onChange={setAmount}
          showMax={true}
          onMax={() => setAmount(position.positionAmount / (10 ** consts.coins[position.indexToken].decimals))}
          showBalance={true}
          balance={position.positionAmount / (10 ** consts.coins[position.indexToken].decimals)}
          balanceLabel='Max'
          className='app-pos-close-token-input'
          tokenEditable={false}
          keepIconPlaceholder={false}
        />
        {/* <PendingOrderSettings title="Close" token='USDC' isLoss={false} isProfit={false} onChange={e =>{setCloseValue(e)}} amount={1000000} />
        <div className="color-F9-bg border rounded-6 mt-16 mb-16">
          <TextSwitch title="Allow up to 1% slippage" className={'border-bottom pt-8 pl-8 pb-8 pr-8'} onChange={e => {setSlippageValue(e)}} />
          <TextSwitch title="Keep Leverage at 10x" className={'pt-8 pl-8 pb-8 pr-8'} onChange={e => {setLeverageValue(e)}} />
        </div> */}
        {mode === 'Limit' && <TokenInput
          token={TOKENS.filter(e => e.symbol === 'usd')[0]}
          titlePrefix={'Price'}
          amount={indexPrice}
          onChange={setIndexPrice}
          showMax={false}
          showBalance={false}
          className='app-pos-close-token-input'
          tokenEditable={false}
          keepIconPlaceholder={false}
        />}
        <InfoList
          content={listContent}
          className='app-pos-close-info-list'
        />
        <Button
          buttonStyle={invalid ? 'disabled' : 'main'}
          text={invalid ? invalid : `Close`}
          onClick={handleClose}
          isLoading={isSubmitLoading}
        />
      </div>
    </Modal>
  )
}

export default PositionClose;