import WormholeBridge, {
  WormholeConnectConfig,
} from '@wormhole-foundation/wormhole-connect'
import grey from '@mui/material/colors/grey';
import green from '@mui/material/colors/green';
import orange from '@mui/material/colors/orange';
import red from '@mui/material/colors/red';

const config: WormholeConnectConfig = {
  mode: 'light',
  env: 'mainnet',
  customTheme: {
    primary: {
      50: '#161718',
      100: '#2d2e30',
      200: '#444548',
      300: '#5b5c60',
      400: '#727479',
      500: '#898b91',
      600: '#a0a2a9',
      700: '#b7b9c1',
      800: '#ced0d9',
      900: '#E5E8F2',
      A100: '#ceced1',
      A200: '#9d9ea4',
      A400: '#535660',
      A700: '#0a0e1c',
    },
    secondary: grey,
    divider: '#a0a2a9',
    background: {
      default: '#F6F6F6',
    },
    text: {
      primary: grey[900],
      secondary: grey[800],
    },
    error: red,
    info: {
      50: '#d1e3f9',
      100: '#c8def7',
      200: '#bfd8f6',
      300: '#b6d3f5',
      400: '#adcdf4',
      500: '#A4C8F3',
      600: '#93b4da',
      700: '#83a0c2',
      800: '#728caa',
      900: '#627891',
      A100: '#A4C8F3',
      A200: '#A4C8F3',
      A400: '#A4C8F3',
      A700: '#A4C8F3',
    },
    success: green,
    warning: orange,
    button: {
      primary: '#ffffff',
      primaryText: grey[900],
      disabled: '#c8cad1',
      disabledText: grey[800],
      action: '#F3A01E',
      actionText: '#000000',
      hover: '#b7b9c1',
    },
    options: {
      hover: '#f9f9fb',
      select: '#F0F0F5',
    },
    card: {
      background: '#ffffff',
      elevation: '10px 10px 30px 15px #CCD2E7',
      secondary: '#F0F0F5',
    },
    popover: {
      background: '#ffffff',
      elevation: '10px 10px 30px 15px #CCD2E7',
      secondary: '#F0F0F5',
    },
    modal: {
      background: '#ffffff',
    },
    font: {
      primary: 'Red Hat Display',
      header: 'Red Hat Display',
    },
  },
  rpcs: {
    solana:
      'https://rpc.ankr.com/solana/2a9ee383a340c9df2975d8f14d0c091167e06ddfc80064a1e4875ce08f44a379',
    sui: 'https://rpc.ankr.com/sui/2a9ee383a340c9df2975d8f14d0c091167e06ddfc80064a1e4875ce08f44a379',
  },
}

const Bridge = () => {
  return <WormholeBridge config={config} />
}

export default Bridge
