import { API, DataAPI, IOrderInfo } from "abex-ts-sdk"
import { useEffect, useState, useRef } from "react"
import { getProvider } from "../app/utils"

export function useReferralData(address: string | undefined, network: string) {
  const [referrees, setReferrees] = useState<any[]>([])
  const [referralDetail, setReferralDetail] = useState<any>()
  const [referralTxs, setReferralTxs] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<number>(0);

  useEffect(() => {
    if (!address) return
    if (isLoading) return
    setIsLoading(true);
    const dataAPI = API.getInstance(network, getProvider(network))

    const fetchReferralData = async () => {
      const referreeList = await dataAPI.getReferreeFromReferrer(address)
      const referrees = []
      for (const referree of referreeList) {
        referrees.push({
          address: referree.address,
          detail: await dataAPI.getReferreeDetail(referree.address),
          date: referree.date,
        })
      }
      const referralDetail = await dataAPI.getReferrerDetail(address)
      const referralTxs = await dataAPI.getReferralTxs(address)
      setReferrees(referrees)
      setReferralDetail(referralDetail)
      setReferralTxs(referralTxs)
    }

    fetchReferralData().catch((err) => {
      setError(err.message)
    }).finally(() => {
      setIsLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, network, refreshToken]);

  const refresh = () => {
    setRefreshToken(prevToken => prevToken + 1);
  };

  return {
    referrees,
    referralDetail,
    referralTxs,
    isLoading,
    error,
    refresh,
  }
}