import { WalletContextState } from "@suiet/wallet-kit";
import { getProvider, suiSymbolToSymbol } from '../app/utils';
import { useState, useEffect, useRef } from 'react';
import { ALP_TOKEN_DECIMALS, IConsts } from "abex-ts-sdk";
import { ACCOUNT_REFRESH_INTERVAL } from "../app/constants";

export interface ITokenBalance {
  symbol: string;
  balance: number;
}

export interface IAccountData {
  balance: ITokenBalance[];
  address: string;
  suiBalance: number;
}

export const useAccountData = (wallet: WalletContextState, network: string, consts: IConsts) => {
  const { account } = wallet;
  const emptyData: IAccountData = { balance: [], address: '', suiBalance: 0 };
  const [accountData, setAccountData] = useState<IAccountData>(emptyData);
  const supportedTokenTypes = Object.values(consts.coins).map(e => e.module);
  supportedTokenTypes.push(`${consts.abexCore.package}::alp::ALP`)
  supportedTokenTypes.push(`0x2::sui::SUI`)
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<number>(0);
  const fetchIntervalId = useRef<number | null>(null);

  useEffect(() => {
    if (!account) {
      return
    }
    if (isLoading) return;
    setIsLoading(true);
    const provider = getProvider(network)
    provider.getAllBalances({ owner: account?.address }).then((balances) => {
      balances = balances.filter((balance) => supportedTokenTypes.includes(balance.coinType));
      const suiBalance = balances.find((balance) => balance.coinType === consts.coins.sui.module)?.totalBalance.toString() || '0';
      setAccountData({
        balance: balances.map((balance) => {
          const symbol = suiSymbolToSymbol(balance.coinType, consts);
          const decimals = symbol === 'alp'? ALP_TOKEN_DECIMALS : consts.coins[symbol].decimals;
          return {
            symbol,
            balance: parseInt(balance.totalBalance.toString()) / (10 ** decimals),
          }
        }),
        /* balance: balances.map((balance) => ({
          symbol: suiSymbolToSymbol(balance.coinType, consts),
          balance: parseInt(balance.totalBalance.toString()) / (10 ** consts.coins[suiSymbolToSymbol(balance.coinType, consts)].decimals),
        })), */
        address: account.address,
        suiBalance: parseInt(suiBalance),
      });
    }).catch((err) => {
      setError(err.message);
    }).finally(() => {
      setIsLoading(false);
    })

    // Clear the previous interval
    if (fetchIntervalId.current !== null) {
      window.clearInterval(fetchIntervalId.current);
    }

    // Setup a new interval
    fetchIntervalId.current = window.setInterval(() => {
      setRefreshToken(prevToken => prevToken + 1);
    }, ACCOUNT_REFRESH_INTERVAL);

    return () => {
      if (fetchIntervalId.current !== null) {
        window.clearInterval(fetchIntervalId.current); // Cleanup interval on component unmount
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account?.address, network, refreshToken]);

  const refresh = () => {
    setRefreshToken(prevToken => prevToken + 1);
  };

  return {
    accountData,
    isLoading,
    error,
    refresh,
  }
}
