import React from 'react';
import "./footer.css";
import LogoFooter from "../../assets/logo-footer.svg";

type Props = {
  className?: string;
};

const Footer: React.FC<Props> = (props) => {
  return (
    <div className={props.className}>
      <div className='footer-content flex-row justify-between align-center'>
        <img src={LogoFooter} style={{width: 91, height: 32}} alt='' />
        <div className='flex-row justify-end align-center'>
          {/* <a href='' className='footer-social-media'></a> */}
          <a href='https://twitter.com/ABExFinance' target='_blank' rel="noreferrer" className='footer-social-media'> </a>
          <a href='mailto:contact@abex.fi' rel="noreferrer" className='footer-social-media'> </a>
          <a href='https://discord.gg/gTbkHAHU8B' target='_blank' rel="noreferrer" className='footer-social-media'> </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;