import { useMemo } from 'react'

import Button from './components/Button'

import './Referral.css'

import GroupIcon from '../assets/group.svg'
import SuccessIcon from '../assets/referral-success.svg'
import SuccessfulIcon from '../assets/referral-successful.svg'
import AddIcon from '../assets/referral-add.svg'
import AddCodeIcon from '../assets/referral-code-add.svg'
import CopyIcon from '../assets/copy.svg'
import TWIcon from '../assets/tw.svg'
import { useEssential } from './App'
import { useReferralData } from '../hooks/useReferralData'
import { formatAddress, formatNumber } from './utils'
import { enqueueSnackbar } from 'notistack'
import Table from './components/Table'
import Skeleton from 'react-loading-skeleton'

const Referral = () => {
  const {
    wallet,
    network,
    consts,
    accountData,
    refreshAccount,
    prices: tokenPrice,
    priceLoading,
  } = useEssential()
  const {
    referrees,
    referralDetail,
    referralTxs,
    isLoading: referralLoading,
    error,
    refresh,
  } = useReferralData(wallet?.address, network)

  const handleCopy = () => {
    if (wallet?.address) {
      const input = document.querySelector(
        '.referral-address-input'
      ) as HTMLInputElement
      input.select()
      document.execCommand('copy')
      enqueueSnackbar('Referral link copied', { variant: 'success' })
    }
  }

  const renderDom = useMemo(() => {
    return (
      <div className="flex m-flex-col justify-between gap-24">
        <div className="flex-1">
          <div className="text-20 font-700">Your Referral Link</div>
          <div className="referral-address-input-box">
            {
              wallet?.address && <input
                className="referral-address-input font-500 text-15"
                type="text"
                value={`https://abex.fi/app/trade?referral=${
                  wallet?.address ?? ''
                }`}
                onClick={handleCopy}
                readOnly
              />
            }
          </div>
          <Button
            buttonStyle={wallet?.address ? "main" : "disabled"}
            text={wallet?.address ? 'Copy Referral Link' : 'Please Connect Wallet'}
            onClick={handleCopy}
            isLoading={false}
          />
        </div>
        <div className="bg-line m-hide"></div>
        <div
          className="referral-code-container flex-1 flex flex-col justify-between items-center"
        >
          <div className="flex justify-between gap-24 w-full h-full">
            <div className="w-full flex-1 flex flex-col items-center justify-center">
              <div className="text-12 font-500 color-black-text-7">
                Trading Volume
              </div>
              <div className="text-20 font-700 color-black-text mt-12">
                {referralLoading? <Skeleton width={80} />: '$' + formatNumber(referralDetail?.volumeUsd || 0, 2)}
              </div>
              <div className="max-w-160 text-10 font-500 color-black-text-5 mt-24 text-center">
                Volume traded by this account with the referral address.
              </div>
            </div>
            <div className="bg-line"></div>
            <div className="w-full flex-1 flex flex-col items-center justify-center">
              <div className="text-12 font-500 color-black-text-7">Rebates</div>
              <div className="text-20 font-700 color-black-text mt-12">
                {referralLoading? <Skeleton width={80} />: '$' + formatNumber(referralDetail?.rebateUsd || 0, 2)}
              </div>
              <div className="max-w-160 text-10 font-500 color-black-text-5 mt-24 text-center">
                Rebates earned by this account as a trader.
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }, [wallet?.address, referralDetail?.volumeUsd, referralDetail?.rebateUsd, referralLoading])

  return (
    <section className="section-container referral">
      <div className="mt-36">
        <div className="w-full referral-card">
          <div className="w-full flex items-center pt-4 pb-4">
            <img src={GroupIcon} alt="" />
            <span className="font-700 text-20 ml-8">ABEx Referrals</span>
          </div>
        </div>
      </div>
      <div className="text-center font-500 text-12 mt-24 mb-24 color-black-text-5">
        Earn rebates (20% of the trading fee) through the ABEx referral program.
        For more information, please read the{' '}
        <a
          href="https://abex-protocol.gitbook.io/abex/referrals/referral-mechanism"
          className="color-blue-text"
          target="_blank"
          rel="noopener noreferrer"
        >
          referral program details.
        </a>
      </div>
      <div className="w-full referral-card">{renderDom}</div>
      <div className="w-full referral-card mt-24">
        <div className="referral-codes-title flex items-center justify-between">
          <div className="flex items-center">
            <span className="text-20 font-700 color-black-text mr-8">
              My Invites ({referrees.length})
            </span>
          </div>
        </div>
        <div className="referral-invites-container">
          {referralLoading? <Skeleton width={80} />: referrees.map((referree, index) => {
            return (
              <div
                key={index}
                className="referree-item"
              >
                <div className="flex items-center justify-between pt-12 pb-12 border-b">
                  <div className="text-20 font-700 pl-12">#{index + 1}</div>
                  <div className="flex items-center pr-12">
                    <div className="mr-24">
                      <div className="text-10 color-black-text-5">Address</div>
                      <div className="text-12 font-700 color-black-text mt-8">
                        {formatAddress(referree.address)}
                      </div>
                    </div>
                    <div>
                      <div className="text-10 color-black-text-5">Time</div>
                      <div className="text-12 font-600 color-black-text mt-8">
                        {referree.date.toISOString().split('T')[0]}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <div className="info-item border-r pt-12 pr-12 pb-12 pl-12">
                    <div className="text-10 color-black-text-5">Trade Volume</div>
                    <div className="text-16 font-700 color-black-text">
                      ${formatNumber(referree.detail.volumeUsd, 2)}
                    </div>
                  </div>
                  <div className="info-item pt-12 pr-12 pb-12 pl-12">
                    <div className="text-10 color-black-text-5">Total Rebates</div>
                    <div className="text-16 font-700 color-black-text">
                      ${formatNumber(referree.detail.rebateUsd, 2)}
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className="w-full referral-card mt-24">
        {referralLoading? <Skeleton width={80} />: referralTxs.length > 0 ? (
          <Table
            titles={['Trader', 'Volume', 'Rebate', 'Tx', 'Date']}
            rows={referralTxs.map((tx, index) => [
              tx.referree,
              '$' + formatNumber(tx.volume, 2),
              '$' + formatNumber(tx.rebate, 2),
              tx.tx,
              tx.date.toISOString().split('T')[0],
            ])}
          />
        ) : (
          <div className="text-center text-12 font-500 color-black-text-7">
            No rebates distribution history yet.
          </div>
        )}
      </div>
    </section>
  )
}

export default Referral
