import React from 'react';
import ReactDOM from 'react-dom/client';
import MainPage from './main-site/App';
import AppPage from './app/App';
import "./common.css";
import "./styles/index.scss";
import 'react-loading-skeleton/dist/skeleton.css'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Trade from './app/Trade';
import Pool from './app/Pool';
import Stats from './app/Stats';
import Bridge from './app/Bridge';
import Referral from './app/Referral';
import { MartianWallet, SuietWallet, SuiWallet, WalletProvider, SurfWallet } from '@suiet/wallet-kit';
import ReactHintFactory from 'react-hint'
import 'react-hint/css/index.css'
import { okxWallet } from './app/constants';
import { IOrderInfo, IPositionInfo } from 'abex-ts-sdk';
const ReactHint = ReactHintFactory(React)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainPage />,
  },
  {
    path: "/app",
    element: <WalletProvider defaultWallets={[
      SuiWallet,
      SuietWallet,
      SurfWallet,
      MartianWallet,
      okxWallet,
    ]}><AppPage /></WalletProvider>,
    children: [
      {
        path: "/app/trade",
        element: <Trade />,
      },
      {
        path: "/app/pool",
        element: <Pool />,
      },
      {
        path: "/app/referral",
        element: <Referral />,
      },
      {
        path: "/app/bridge",
        element: <Bridge />,
      },
      {
        path: "/app/stats",
        element: <Stats />,
      },
    ]
  }
]);

declare global {
  interface Window {
    closedPositions: IPositionInfo[];
    clearedPositionIds: string[];
    executedOrders: IOrderInfo[];
    clearedOrderIds: string[];
  }
}

root.render(
  <React.StrictMode>
    <ReactHint autoPosition events />
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
