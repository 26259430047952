import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { ConnectButton } from '@suiet/wallet-kit'
import './header.css'
import Drawer from './Drawer/index'
import AppLogo from '../../assets/logo-header.svg'
import MobileExpand from '../../assets/mobile-expand.svg'
import MobileClose from '../../assets/mobile-close.svg'
import SuiSvg from '../../assets/sui.svg'
import SafeSvg from '../../assets/safe.svg'
import RpcSetting from './Setting/Rpc'
import { useRpc } from '../../hooks/useRpc'

const Header: React.FC = () => {
  const [getStatus, setStatus] = useState(false)

  const {
    isCustom,
    setIsCustom,
    currentRpcIndex,
    setCurrentRpcIndex,
    rpcList,
    delayTimes,
  } = useRpc()

  return (
    <>
      <div className="section-container is-pc app-header flex-row align-center justify-between">
        <div className="flex-row flex-1 align-center justify-start">
          <NavLink to="/">
            <img src={AppLogo} className="header-img" alt="" />
          </NavLink>
          <div className="sui-grant">
            <img src={SuiSvg} alt="Sui" />
            <span>Grant by Sui</span>
          </div>
          <div className="flex flex-1 align-center justify-center">
            <nav className="flex align-center justify-center">
              <NavLink
                to="/app/trade"
                className={({ isActive }) =>
                  isActive ? 'app-header-page-selected' : ''
                }
              >
                Trade
              </NavLink>
              <NavLink
                to="/app/pool"
                className={({ isActive }) =>
                  isActive ? 'app-header-page-selected' : ''
                }
              >
                Pool
              </NavLink>
              <NavLink
                to="/app/referral"
                className={({ isActive }) =>
                  isActive ? 'app-header-page-selected' : ''
                }
              >
                Referral
              </NavLink>
              <NavLink
                to="/app/bridge"
                className={({ isActive }) =>
                  isActive ? 'app-header-page-selected' : ''
                }
              >
                Bridge
              </NavLink>
              {/* <NavLink to="/app/stats" className={({isActive}) => isActive? "app-header-page-selected": ""}>Stats</NavLink> */}
              {/* <NavLink to="/app/beta" className={({isActive}) => isActive? "app-header-page-selected": ""}>Beta+</NavLink> */}
              {/* <NavLink to="/app/event" className={({isActive}) => isActive? "app-header-page-selected": ""}>Event</NavLink> */}
              <a
                href="https://app.sentio.xyz/abex/abex/dashboards/D3fkXU3z"
                target="_blank"
                rel="noreferrer"
              >
                Stats
              </a>
              <a
                href="https://abex-protocol.gitbook.io/abex/"
                target="_blank"
                rel="noreferrer"
              >
                Docs
              </a>
            </nav>
          </div>
        </div>
        <div className="flex-row align-center justify-end">
          <ConnectButton children="Connect Wallet" />
          <a className="safe" href="https://drive.google.com/file/d/1fN8GsLiRySjYrHUKe0ZIvlsK7wBCL2kj/view?usp=sharing" target="_blank" rel="noopener noreferrer">
            <img src={SafeSvg} alt="Safe" />
          </a>
          <RpcSetting
            isCustom={isCustom}
            setIsCustom={setIsCustom}
            currentRpcIndex={currentRpcIndex}
            setCurrentRpcIndex={setCurrentRpcIndex}
            rpcList={rpcList}
            delayTimes={delayTimes}
          />
        </div>
      </div>
      <div className="section-container is-mobile mobile-height app-header flex-row align-center justify-between">
        <div className="flex-row mobile-container flex-1 align-center justify-start">
          <NavLink to="/">
            <img src={AppLogo} className="header-img" alt="" />
          </NavLink>
          <div className="flex-row align-center justify-end">
            <ConnectButton children="Connect Wallet" />
          </div>
          <div className="flex mobile-expand flex-1 align-center justify-center">
            <a className="safe" href="https://drive.google.com/file/d/1fN8GsLiRySjYrHUKe0ZIvlsK7wBCL2kj/view?usp=sharing" target="_blank" rel="noopener noreferrer">
              <img src={SafeSvg} alt="Safe" />
            </a>
            <RpcSetting
              isCustom={isCustom}
              setIsCustom={setIsCustom}
              currentRpcIndex={currentRpcIndex}
              setCurrentRpcIndex={setCurrentRpcIndex}
              rpcList={rpcList}
              delayTimes={delayTimes}
            />
            <img
              src={getStatus ? MobileClose : MobileExpand}
              alt=""
              onClick={() => {
                setStatus(!getStatus)
              }}
            />
            {/* <nav className="flex align-center justify-center">
          <NavLink to="/app/trade" className={({isActive}) => isActive? "app-header-page-selected": ""}>Trade</NavLink>
          <NavLink to="/app/pool" className={({isActive}) => isActive? "app-header-page-selected": ""}>Pool</NavLink>
          {/* <NavLink to="/app/stats" className={({isActive}) => isActive? "app-header-page-selected": ""}>Stats</NavLink> */}
            {/* <NavLink to="/app/beta" className={({isActive}) => isActive? "app-header-page-selected": ""}>Beta+</NavLink> */}
            {/* <NavLink to="/app/event" className={({isActive}) => isActive? "app-header-page-selected": ""}>Event</NavLink> *
          <a href="https://abex-protocol.gitbook.io/abex/" target="_blank" rel="noreferrer">Docs</a>
        </nav> */}
          </div>
        </div>
      </div>
      {getStatus && <Drawer closeDrawer={() => setStatus(false)} />}
    </>
  )
}

export default Header
