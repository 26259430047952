import React, { useRef } from 'react';
import useTriggerClassRef from '../../hooks/useTriggerClassRef';
import "./section-intro.css";

type Props = {
  className?: string;
};

const SectionIntro: React.FC<Props> = (props) => {
  const elementToAnimate2 = useRef(null)
  const elementToAnimate3 = useRef(null)
  const elementToAnimate4 = useRef(null)
  const elementToAnimate = useTriggerClassRef('intro-animate-fade-in', [
    ['intro-animate-fade-in-2', elementToAnimate2],
    ['intro-animate-fade-in-3', elementToAnimate3],
    ['intro-animate-fade-in-4', elementToAnimate4],
  ])
  const elementToAnimateTitle = useTriggerClassRef('title-animate-fade-in')
  return (
    <div className={props.className}>
      <h2 className='section-title' ref={elementToAnimateTitle}>
        A Next-generation high-performance decentralized derivative protocol
      </h2>
      <div className='card-max-white intro-card'>
        <p>
          <span>ABEx would have the best decentralized trading experience</span>  for traders, while keeping the lucrative rewards to liquidity providers.
        </p>
        <div className='intro-card-content flex-row justify-between'>
          <div className='flex-col align-center justify-start' ref={elementToAnimate}>
            <div />
            <h3>Token Swap</h3>
            <div className='comming-soon-icon align-center justify-center'>Coming Soon</div>
          </div>
          <div className='flex-col align-center justify-start' ref={elementToAnimate2}>
            <div />
            <h3>Leveraged Trading</h3>
            <div className='comming-soon-icon align-center justify-center'>Coming Soon</div>
          </div>
          <div className='flex-col align-center justify-start' ref={elementToAnimate3}>
            <div />
            <h3>Provide Liquidity</h3>
            <div className='comming-soon-icon align-center justify-center'>Coming Soon</div>
          </div>
        </div>
        <div className='intro-logo' ref={elementToAnimate4} />
      </div>
    </div>
  );
};

export default SectionIntro;