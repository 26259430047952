import React, { useRef } from 'react';
import useTriggerClassRef from '../../hooks/useTriggerClassRef';
import "./section-ab.css";

import AlphaCard from "../../assets/alpha-card.svg";
import BetaCard from "../../assets/beta-card.svg";
import BetaPlusCard from "../../assets/betaplus-card.svg";
import BetaPlusDetail from "../../assets/beta-plus-detail.svg";

type Props = {
  className?: string;
};

const SectionAB: React.FC<Props> = (props) => {
  const elementToAnimateTitle = useTriggerClassRef('title-animate-fade-in')
  const elementToAnimate2 = useRef(null)
  const elementToAnimate3 = useRef(null)
  const elementToAnimate = useTriggerClassRef('ab-animate-fade-in', [
    ['ab-animate-fade-in-2', elementToAnimate2],
    ['ab-animate-fade-in-3', elementToAnimate3],
  ])
  return (
    <div className={props.className}>
      <h2 className='section-title' ref={elementToAnimateTitle}>
        ABEx is built to seek alpha for traders while settling beta for liquidity providers
      </h2>
      <div className='ab-content'>
        <div className='card-max-white'>
          <h3>Alpha</h3>
          <span>High leverage perpetual trading with zero price impact. No accounts / L2 deposits needed</span>
          <img src={AlphaCard} alt='' ref={elementToAnimate} />
        </div>
        <div className='card-max-white'>
          <h3>Beta</h3>
          <span>Built for liquidity providers, a robust portfolio consists of high-liquidity assets</span>
          <img src={BetaCard} alt='' ref={elementToAnimate2} />
        </div>
        <div className='card-max-white'>
          <h3>Beta+</h3>
          <span>Make the most use of your liquidity by leverage your ALP position and seek yield strategy with external protocols</span>
          <img src={BetaPlusCard} alt='' ref={elementToAnimate3} />
          <img src={BetaPlusDetail} alt='' />
        </div>
      </div>

    </div>
  );
};

export default SectionAB;