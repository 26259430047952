import React, { useRef } from 'react';
import "./section-bc.css";

import SuiPic from '../../assets/sui.png';
import SuiSmallLogo from '../../assets/sui-logo-small.svg';
import useTriggerClassRef from '../../hooks/useTriggerClassRef';

type Props = {
  className?: string;
};

const SectionBC: React.FC<Props> = (props) => {
  const elementToAnimate2 = useRef(null)
  const elementToAnimate3 = useRef(null)
  const elementToAnimate = useTriggerClassRef('bc-animate-fade-in', [
    ['bc-animate-fade-in-2', elementToAnimate2],
    ['bc-animate-fade-in-3', elementToAnimate3],
  ])
  return (
    <div className={props.className}>
      <div className='card-max-white bc-content-card'>
        <h3>ABEx Powered By Sui</h3>
        <span>Sui is the first permissionless Layer 1 blockchain designed from the ground up to enable creators and developers to build experiences that cater to the next billion users in web3.
          <br />
          Its main technical highlights are the use of Move language, parallelizable account structure, parallel processing engine, BFT model of consensus, etc.
        </span>
        <a href='https://sui.io' target='_blank' rel="noreferrer" className='bc-content-button align-center justify-center' ref={elementToAnimate}>
          Learn more about Sui
        </a>
        <div className='bc-content-big-pic' ref={elementToAnimate3}>
          <img src={SuiPic} alt='' />
        </div>
        <div className='bc-content-small-pic' ref={elementToAnimate2}>
          <img src={SuiSmallLogo} alt='' />
        </div>
      </div>
    </div>
  );
};

export default SectionBC;