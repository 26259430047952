import React, { useState } from 'react';
import Modal from 'react-modal';
import './SwapConfirm.css'
import { executeApiCall, formatNumber, getCoins, localSymbolToOriginal } from '../utils';
import { IAccountData } from '../../hooks/useAccountData';
import { IConsts } from 'abex-ts-sdk';
import { WalletContextState } from '@suiet/wallet-kit';
import Button from './Button';
import ModalHeader from './ModalHeader';
import ConfirmSwap from '../../assets/confirm-swap.svg';
import InfoList from './InfoList';


type Props = {
  showSwapConfirm: boolean;
  setShowSwapConfirm: (show: boolean) => void;
  accountData: IAccountData;
  consts: IConsts;
  swapFromToken: string;
  swapToToken: string;
  payAmount: number;
  secondAmount: number;
  clearStates: () => void;
  wallet: WalletContextState;
  network: string;
  refreshAccount: () => void;
  swapToPrice: number;
  swapFee?: number;
}

const SwapConfirm: React.FC<Props> = ({
  showSwapConfirm,
  setShowSwapConfirm,
  accountData,
  consts,
  swapFromToken,
  swapToToken,
  payAmount,
  secondAmount,
  clearStates,
  wallet,
  network,
  refreshAccount,
  swapToPrice,
  swapFee=0,
}) => {
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const performSwap = () => {
    setIsSubmitLoading(true)
    executeApiCall(
      async (api) => {
        const coins = await getCoins(accountData.address, consts.coins[swapFromToken].module, network);
        const coinObjects = coins.map((e) => e.objectId);
        return api.swap(
          swapFromToken,
          swapToToken,
          BigInt(parseInt((payAmount * (10 ** consts.coins[swapFromToken].decimals)).toString())),
          coinObjects,
        );
      },
      (res) => {
        clearStates();
        return `Success send tx: ${res?.digest}`;
      },
      (e) => `Error: ${e.message}`,
      wallet,
      network,
      [refreshAccount],
      [() => setShowSwapConfirm(false)],
    ).finally(() => setIsSubmitLoading(false));
  }

  return (
    <Modal
      className='app-pos-close-main'
      isOpen={showSwapConfirm}
      onRequestClose={() => {
        setShowSwapConfirm(false)
      }}
      shouldCloseOnOverlayClick={true}
    >
      <div className='app-swap-confirm-card'>
        <ModalHeader
          icon={ConfirmSwap}
          text={`Confirm Swap`}
          setShowModal={() => setShowSwapConfirm(false)}
        />
        <div className='app-swap-confirm-token-info flex-row justify-between'>
          <div className='app-swap-confirm-token-info-item flex-col'>
            <div className='app-swap-confirm-token-info-item-title flex-row justify-between'>
              <div>Pay</div>
              <div>{localSymbolToOriginal(swapFromToken)}</div>
            </div>
            <div className='app-swap-confirm-token-info-item-value'>
              {formatNumber(payAmount, 4)}
            </div>
          </div>
          <div className='app-swap-confirm-token-info-item flex-col'>
            <div className='app-swap-confirm-token-info-item-title flex-row justify-between'>
              <div>Receive</div>
              <div>{localSymbolToOriginal(swapToToken)}</div>
            </div>
            <div className='app-swap-confirm-token-info-item-value'>
              {formatNumber(secondAmount - swapFee, 4)}
            </div>
          </div>
        </div>
        <InfoList
          content={[
            ['Fee', `${formatNumber(swapFee)} ${localSymbolToOriginal(swapToToken)} ($${formatNumber(swapToPrice * swapFee)})`],
          ]}
          className='app-swap-confirm-info-list'
        />
        <Button
          buttonStyle={'main'}
          text={`Confirm Swap`}
          onClick={performSwap}
          isLoading={isSubmitLoading}
        />
      </div>
    </Modal>
  )
}

export default SwapConfirm