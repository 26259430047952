import { API, DataAPI } from "abex-ts-sdk"
import { useEffect, useState } from "react"
import { getProvider } from "../app/utils"

export function useHistories(address: string, network: string) {
  const [histories, setHistories] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<number>(0);

  useEffect(() => {
    if (!address) return
    if (isLoading) return
    setIsLoading(true);
    const dataAPI = API.getInstance(network, getProvider(network))

    const fetchHistories = async () => {
      const histories = await dataAPI.getProxiedHistories(address)
      setHistories(histories)
    }
    
    fetchHistories().catch((err) => {
      setError(err.message)
    }).finally(() => {
      setIsLoading(false)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, network, refreshToken]);

  const refresh = () => {
    setRefreshToken(prevToken => prevToken + 1);
  };

  return {
    histories,
    isLoading,
    error,
    refresh,
  }
}
