import { NavLink } from "react-router-dom";
import Medium from '../../../assets/footer/medium.svg'
import Discord from '../../../assets/footer/discord.svg'
import Twitter from '../../../assets/footer/twitter.svg'
import Email from '../../../assets/footer/email.svg'
import Guide from '../../../assets/footer/guide.svg'
import Logo from '../../../assets/footer/logo.svg'
type Props = {
  closeDrawer: () => void
}
const Drawer = ({ closeDrawer }: Props) => {
  const data = [
    {
      name: 'Trade',
      url: '/app/trade',
    },
    {
      name: 'Pool',
      url: '/app/pool',
    },
    {
      name: 'Referral',
      url: '/app/referral',
    },
    {
      name: 'Bridge',
      url: '/app/bridge',
    },
    // {
    //   name: 'Event',
    //   url: '/',
    // },
  ]
  return (
    <div className="drawer flex flex-col pl-16 pr-16 font-500 color-white-color" onClick={closeDrawer}>
      <div className="drawer-text flex flex-col align-end text-16">
        {data.map((item) => {
          return (
            <NavLink to={item.url} key={item.name} className={({isActive}) => isActive? "color-blue-bg color-white-text ease-in rounded-6": "ease-in rounded-6"}>
              <span>
                {item.name}
              </span>
            </NavLink>
          )
        })}
        <a
          href="https://app.sentio.xyz/abex/abex/dashboards/D3fkXU3z"
          target="_blank"
          rel="noreferrer"
        >
          <span>Stats</span>
        </a>
        <a
          href="https://abex-protocol.gitbook.io/abex/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>Docs</span>
        </a>
      </div>
      <div className="media-link drawer-link flex-col justify-center align-end">
        <a
          href="https://abexprotocol.medium.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Medium} alt="Medium" className="app-footer-logo" />
        </a>
        <a
          href="https://discord.gg/gTbkHAHU8B"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Discord} alt="Discord" className="app-footer-logo" />
        </a>
        <a
          href="https://twitter.com/ABExFinance"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Twitter} alt="Twitter" className="app-footer-logo" />
        </a>
        <a
          href="mailto: abex@abex.fi"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Email} alt="Email" className="app-footer-logo" />
        </a>
        <a href="https://abex-protocol.gitbook.io/abex/" target="_blank" rel="noopener noreferrer">
          <img src={Guide} alt="Email" className="app-footer-logo" />
        </a>
        <a href="https://drive.google.com/file/d/1e8n1IxEYdJcGxJ2GdGDZSfJYzAMyzq5l/view" target="_blank" rel="noopener noreferrer">
          <img src={Logo} alt="Email" className="app-footer-logo" />
        </a>
      </div>
    </div>
  )
}

export default Drawer
