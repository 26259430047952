import pLimit from 'p-limit'
import { useEffect, useState } from 'react'
import { RPC_LIST } from '../app/utils'
import { useNetwork } from './useNetwork'
import { JsonRpcProvider, Connection } from '@mysten/sui.js'

export const useRpc = () => {
  const network = useNetwork()

  const rpcList = RPC_LIST[network]

  const [isCustom, setIsCustom] = useState(!!localStorage.getItem('is-custom-rpc'))
  const [currentRpcIndex, setCurrentRpcIndex] = useState(
    localStorage.getItem('custom-rpc-index')
      ? Number(localStorage.getItem('custom-rpc-index'))
      : 0
  )
  const [delayTimes, setDelayTimes] = useState<
    { number: string; time: number }[]
  >(
    new Array(rpcList.length).fill({
      number: '0',
      time: 0,
    })
  )

  const limit = pLimit(6)

  const getRpcDelayTime = async (url: string) => {
    const provider = new JsonRpcProvider(
      new Connection({
        fullnode: url,
      })
    )
    try {
      const timeStart = new Date().getTime()
      const number = await provider.getLatestCheckpointSequenceNumber()
      const timeEnd = new Date().getTime()
      const time = timeEnd - timeStart
      return {
        number,
        time,
      }
    } catch (error) {
      return {
        number: '0',
        time: 0,
      }
    }
  }

  const setAutoRpc = () => {
    let minIndex = 0
    delayTimes.forEach((item, index) => {
      if (Number(item.number) > 0) {
        if (item.time < delayTimes[minIndex].time) {
          minIndex = index
        }
      }
    })
    setCurrentRpcIndex(minIndex)
    localStorage.setItem('custom-rpc-index', minIndex.toString())
  }

  const handleActions = async () => {
    const actions = rpcList.map((item) =>
      limit(() => getRpcDelayTime(item.rpc))
    )
    const result = await Promise.all(actions)
    setDelayTimes(result)
  }

  useEffect(() => {
    handleActions()

    const timer = setInterval(() => {
      handleActions()
    }, 10000)

    return () => {
      clearInterval(timer)
    }
  }, [])

  useEffect(() => {
    if (!isCustom) {
      setAutoRpc()
    }
  }, [isCustom, delayTimes])

  return {
    isCustom,
    setIsCustom,
    rpcList,
    currentRpcIndex,
    setCurrentRpcIndex,
    delayTimes,
    setDelayTimes,
  }
}
