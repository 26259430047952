import { API, DataAPI, IStakePool, IStaked } from "abex-ts-sdk"
import { useEffect, useState } from "react"
import { getProvider } from "../app/utils"

export function useStakeInfo(address: string, network: string) {
  const [stakePool, setStakeInfo] = useState<IStakePool>()
  const [staked, setStaked] = useState<IStaked>()
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<number>(0);

  useEffect(() => {
    if (isLoading) return
    setIsLoading(true);
    const dataAPI = API.getInstance(network, getProvider(network))

    const fetchStakeInfo = async () => {
      const stakeInfoList = await dataAPI.getStakePool()
      setStakeInfo(stakeInfoList)
    }

    const fetchStaked = async () => {
      if (!address) return
      const stakedInfo = await dataAPI.getStaked(address)
      setStaked(stakedInfo)
    }
    
    Promise.all([fetchStakeInfo(), fetchStaked()]).catch((err) => {
      setError(err.message)
    }).finally(() => {
      setIsLoading(false)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, network, refreshToken]);

  const refresh = () => {
    setRefreshToken(prevToken => prevToken + 1);
  };

  return {
    stakePool,
    staked,
    isLoading,
    error,
    refresh,
  }
}
