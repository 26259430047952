import { API, DataAPI, IOrderInfo } from "abex-ts-sdk"
import { useEffect, useState } from "react"
import { getProvider } from "../app/utils"

export function useOrder(address: string, network: string) {
  const [orders, setOrders] = useState<IOrderInfo[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<number>(0);

  useEffect(() => {
    if (!address) return
    if (isLoading) return
    setIsLoading(true);
    const dataAPI = API.getInstance(network, getProvider(network))

    const fetchOrder = async () => {
      const capInfoList = await dataAPI.getOrderCapInfoList(address)
      const infoList = await dataAPI.getOrderInfoList(capInfoList, address)
      setOrders(infoList)
    }
    
    fetchOrder().catch((err) => {
      setError(err.message)
    }).finally(() => {
      setIsLoading(false)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, network, refreshToken]);

  const refresh = () => {
    setRefreshToken(prevToken => prevToken + 1);
  };

  return {
    orders,
    isLoading,
    error,
    refresh,
  }
}
