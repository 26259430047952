import { API, DataAPI, IConsts, IVaultInfo } from "abex-ts-sdk"
import { useEffect, useState } from "react"
import { getProvider } from "../app/utils"

export function useVaultInfo(network: string, consts: IConsts) {
  const [vaultInfoMap, setVaultInfo] = useState<{[key: string]: IVaultInfo}>({})
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<number>(0);

  useEffect(() => {
    if (isLoading) return;
    setIsLoading(true);
    const dataAPI = API.getInstance(network, getProvider(network))
    Promise.all(Object.keys(consts.abexCore.vaults).map(async (collateralToken) => {
      const vaultInfo = await dataAPI.getVaultInfo(collateralToken)
      setVaultInfo(prevMap => ({
        ...prevMap,
        [collateralToken]: vaultInfo,
      }))
    })).then(() => {
    }).catch((err) => {
      setError(err.message)
    }).finally(() => {
      setIsLoading(false)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [network, refreshToken]);

  const refresh = () => {
    setRefreshToken(prevToken => prevToken + 1);
  };

  return {
    vaultInfoMap,
    isLoading,
    error,
    refresh,
  }
}
