import { API, DataAPI, IPositionInfo } from "abex-ts-sdk"
import { useEffect, useState } from "react"
import { getProvider } from "../app/utils"

export function usePosition(address: string, network: string) {
  const [positions, setPositions] = useState<IPositionInfo[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<number>(0);

  useEffect(() => {
    if (!address) return
    if (isLoading) return
    setIsLoading(true);
    const dataAPI = API.getInstance(network, getProvider(network))

    const fetchPosition = async () => {
      const capInfoList = await dataAPI.getPositionCapInfoList(address)
      const infoList = await dataAPI.getPositionInfoList(capInfoList, address)
      setPositions(infoList)
    }
    
    fetchPosition().catch((err) => {
      setError(err.message)
    }).finally(() => {
      setIsLoading(false)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, network, refreshToken]);

  const refresh = () => {
    setRefreshToken(prevToken => prevToken + 1);
  };

  return {
    positions,
    isLoading,
    error,
    refresh,
  }
}
