import React, { useEffect, useMemo, useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip
} from "recharts";
import dayjs from "dayjs";
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import "./Pool.css";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import ALPIcon from "../assets/alp-icon.svg";
import Network from "../assets/inter-network-active.svg";
import PoolIcon from "../assets/pool/pool.svg";
import Pool1 from "../assets/pool/1.svg";
import Pool2 from "../assets/pool/2.svg";
import Pool3 from "../assets/pool/3.svg";
import AlpLong from "../assets/alp-long.svg";
import AlpShort from "../assets/alp-short.svg";
import DetailSection from "./components/DetailSection";
import { MAIN_COLOR, POOL_OP_MIN_FEE, TOKENS } from "./constants";
import TokenInput from "./components/TokenInput";
import Table from "./components/Table";
import { Token } from './models';
import { executeApiCall, formatNumber, getCoins, localSymbolToOriginal, priceToString } from "./utils";
import { useValuateMarket } from '../hooks/useValuateMarket';
import { useEssential } from "./App";
import Button from "./components/Button";
import { useRebaseRate } from "../hooks/useRebaseRate";
import Skeleton from "react-loading-skeleton";
import { useVaultInfo } from "../hooks/useVaultInfo";
import { ALP_TOKEN_DECIMALS } from "abex-ts-sdk";
import { useStakeInfo } from "../hooks/useStakeInfo";


type Props = {
  className?: string;
};

const miningTableTitle = [
  'Token',
  'Price',
  'Available',
  'Reserve',
  'Target Weight',
  'Current Weight',
  'Utilization',
]

const chartTimeObj = {
  1: {
    start: 'now-1d',
    step: 3600
  },
  2: {
    start: 'now-7d',
    step: 7200
  },
  3: {
    start: 'now-30d',
    step: 10800
  },
  4: {
    start: 'now-90d',
    step: 16200
  },
  5: {
    start: 'now-180d',
    step: 32400
  }
}

const Pool: React.FC<Props> = (props) => {
  const { wallet, network, consts, accountData, refreshAccount, prices: tokenPrice, priceLoading } = useEssential()
  const inputTokens = TOKENS.filter(x => Object.keys(consts.abexCore.vaults).indexOf(x.symbol) !== -1)
  const [mode, setMode] = useState<"buy" | "sell">("buy");
  const [isStake, setIsStake] = useState(true);
  const [isOneStep, setIsOneStep] = useState(false);
  const [amount, setAmount] = useState<number | null>(null);
  const [stakeAmount, setStakeAmount] = useState<number | null>(null);
  const [token, setToken] = useState<Token>(inputTokens[2]);
  const { marketInfo, isLoading: marketLoading, refresh: refreshMarket } = useValuateMarket(network)
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const { vaultInfoMap, isLoading: vaultInfoLoading, refresh: refreshVaultInfo } = useVaultInfo(network, consts)
  const { stakePool, staked, isLoading: stakePoolLoading, refresh: refreshStakePool } = useStakeInfo(accountData.address, network)

  const [slideIndex, setSlideIndex] = useState(1);

  const [chartTime, setChartTime] = useState(1);
  const [chartWidth, setChartWidth] = useState(870);
  const [chartData, setChartData] = useState<any>([]);

  const totalWeight = Object.values(consts.abexCore.vaults).map(e => parseInt(e.weight)).reduce((a, b) => a + b, 0)

  const secondAmount = !marketLoading && !priceLoading ? (mode === 'buy' ? (marketInfo.alpPrice ? tokenPrice[token.symbol] * (amount || 0) / marketInfo.alpPrice : 0) : (marketInfo.alpPrice ? marketInfo.alpPrice * (amount || 0) / tokenPrice[token.symbol] : 0)) : null
  const { rebaseRate, isLoading: rebaseRateLoading } = useRebaseRate(token.symbol, mode === 'buy', network, ((mode === 'buy'? amount: secondAmount) || 0) * (10 ** consts.coins[token.symbol].decimals))
  const alpBalance = accountData.balance.length ? accountData.balance.filter(e => e.symbol === 'alp')[0]?.balance || 0 : 0
  const stakedBalance = staked?.amount ? Number(staked.amount) / (10 ** ALP_TOKEN_DECIMALS) : 0 || 0
  const stakeApr = Number(stakePool?.reward || 0) / (10 ** consts.coins['sui'].decimals) * tokenPrice['sui'] / (((stakePool?.endTime || 0) - (+new Date() / 1000)) / (3600 * 24) * marketInfo.alpPrice * Number(stakePool?.stakedAmount || 0) / (10 ** ALP_TOKEN_DECIMALS)) * 365 * 100;

  const getALPData = useMemo(() => {
    const firstPrice = (chartData[0] ? chartData[0].value : 0) || 0;
    const nowPrice = Number(marketInfo.alpPrice) > 0 ? Number(formatNumber(marketInfo.alpPrice, 4)) : 0;
    const diffPrice = nowPrice - firstPrice;
    const percentage = (diffPrice / firstPrice * 100).toFixed(2);
    if (diffPrice >= 0) {
      return {
        icon: AlpLong,
        className: 'color-green-text',
        percentage: `+${percentage}%`,
        diffPrice: `+$${Number(diffPrice).toFixed(4)}`
      };
    }
    return {
      icon: AlpShort,
      className: 'color-red-text',
      percentage: `${percentage}%`,
      diffPrice: `-$${Number(diffPrice).toFixed(4).replace('-', '')}`
    };
  }, [chartData, marketInfo.alpPrice]);

  const clearStates = (mode: 'buy' | 'sell') => {
    setAmount(null)
    setToken(mode === 'buy' ? inputTokens[2] : inputTokens[0])
  }

  const getChartData = async (val: keyof typeof chartTimeObj) => {
    const data = chartTimeObj[val];
    const res = await fetch('https://api.abex.fi/insights', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "projectOwner": "abex",
        "projectSlug": "abex",
        "version": 49,
        "timeRange": {
            "start": data.start,
            "end": "now",
            "step": data.step,
            "timezone": "Asia/Shanghai"
        },
        "limit": 20,
        "queries": [
            {
                "metricsQuery": {
                    "query": "TVL_by_Token_USD",
                    "alias": "",
                    "id": "a",
                    "labelSelector": {},
                    "aggregate": {
                        "op": "SUM",
                        "grouping": []
                    },
                    "functions": [],
                    "disabled": true
                },
                "dataSource": "METRICS",
                "sourceName": ""
            },
            {
                "metricsQuery": {
                    "query": "ALP_Amount",
                    "alias": "",
                    "id": "b",
                    "labelSelector": {},
                    "aggregate": null,
                    "functions": [],
                    "disabled": true
                },
                "dataSource": "METRICS",
                "sourceName": ""
            }
        ],
        "formulas": [
            {
                "expression": "a/b",
                "alias": "Alp price",
                "id": "A",
                "disabled": false,
                "functions": []
            }
        ],
        "bypassCache": true
      })
    });
    const json = await res.json();
    const values = json?.results[0]?.matrix?.samples[0]?.values ?? [];
    const result = values.filter((item: any) => item.value > 0.9);
    setChartData(result);
  }

  const handleSetChartTime = (val: keyof typeof chartTimeObj) => {
    setChartTime(val);
    getChartData(val);
  }

  useEffect(() => {
    getChartData(1);
    const windowWidth = window.innerWidth;
    if (windowWidth < 960) {
      setChartWidth(windowWidth - 68);
    } else {
      setChartWidth(870);
    }
  }, []);

  const handleInput = (str: string) => {
    if (str) {
      const regex = new RegExp(`^\\D*(\\d*(?:\\.\\d{0,18})?).*$`, 'g')
      const value = str.replace(regex, '$1');
      if (/^0\d/.test(value)) {
        const newValue = parseFloat(value.replace('0', ''));
        setStakeAmount(newValue);
      } else {
        setStakeAmount(parseFloat(value));
      }
    } else {
      setStakeAmount(0);
    }
  }

  const checkVaild = () => {
    if (!accountData.address) {
      return 'Please connect wallet'
    }
    if (!amount) {
      return 'Invalid Pay Amount'
    }
    if (mode === 'buy' && amount > (accountData.balance.filter(b => b.symbol === token.symbol)[0]?.balance || 0)) {
      return 'Insufficient Balance'
    }
    if (mode === 'sell' && amount > alpBalance) {
      return 'Insufficient Balance'
    }
    return false
  }

  const checkValidStake = () => {
    if (!accountData.address) {
      return 'Please connect wallet'
    }
    if (!stakeAmount) {
      return 'Invalid Stake Amount'
    }
    if (isStake && stakeAmount > alpBalance) {
      return 'Insufficient Balance'
    }
    if (!isStake && stakeAmount > stakedBalance) {
      return 'Insufficient Balance'
    }
    return false
  }
  const invalid = checkVaild()
  const invalidStake = checkValidStake()

  const buyALP = () => {
    if (invalid) {
      return
    }
    setIsSubmitLoading(true);
    executeApiCall(
      async (api) => {
        const coins = await getCoins(accountData.address, consts.coins[token.symbol.toLowerCase()].module, network);
        const coinObjects = coins.map((e) => e.objectId);
        return api.deposit(token.symbol, coinObjects, (amount || 0) * (10 ** consts.coins[token.symbol].decimals));
      },
      (res) => {
        clearStates('buy');
        return `Success send tx: ${res?.digest}`;
      },
      (e) => `Error: ${e.message}`,
      wallet,
      network,
      [
        refreshAccount,
        refreshMarket,
        refreshVaultInfo,
      ],
    ).finally(() => setIsSubmitLoading(false));
  }


  const sellALP = () => {
    if (invalid) {
      return
    }
    setIsSubmitLoading(true);
    executeApiCall(
      async (api) => {
        const alpCoins = await getCoins(accountData.address, `${consts.abexCore.package}::alp::ALP`, network);
        const alpCoinObjects = alpCoins.map((e) => e.objectId);
        return api.withdraw(token.symbol, alpCoinObjects, (amount || 0) * (10 ** ALP_TOKEN_DECIMALS));
      },
      (res) => {
        clearStates('sell');
        return `Success send tx: ${res?.digest}`;
      },
      (e) => `Error: ${e.message}`,
      wallet,
      network,
      [
        refreshAccount,
        refreshMarket,
        refreshVaultInfo,
      ],
    ).finally(() => setIsSubmitLoading(false));
  }

  const stakeALP = () => {
    if (invalidStake) {
      return
    }
    setIsSubmitLoading(true);
    executeApiCall(
      async (api) => {
        const alpCoins = await getCoins(accountData.address, `${consts.abexCore.package}::alp::ALP`, network);
        const alpCoinObjects = alpCoins.map((e) => e.objectId);
        return api.stake(
          alpCoinObjects,
          BigInt(Math.floor((stakeAmount || 0) * (10 ** ALP_TOKEN_DECIMALS))),
          consts.abexStaking.pool,
        );
      },
      (res) => {
        setStakeAmount(0);
        return `Success send tx: ${res?.digest}`;
      },
      (e) => `Error: ${e.message}`,
      wallet,
      network,
      [
        refreshAccount,
        refreshStakePool,
      ]
    ).finally(() => setIsSubmitLoading(false));
  }

  const unstakeALP = (isClaim = false) => {
    if (invalidStake && !isClaim) {
      return
    }
    if (!staked?.credentials) {
      return
    }
    setIsSubmitLoading(true);
    executeApiCall(
      async (api) => {
        return api.unstake(
          staked?.credentials,
          BigInt(Math.floor((isClaim ? 0 : (stakeAmount || 0) * (10 ** ALP_TOKEN_DECIMALS)))),
          consts.abexStaking.pool,
        );
      },
      (res) => {
        setStakeAmount(0);
        return `Success send tx: ${res?.digest}`;
      },
      (e) => `Error: ${e.message}`,
      wallet,
      network,
      [
        refreshAccount,
        refreshStakePool,
      ],
    ).finally(() => setIsSubmitLoading(false));
  }

  return (
    <div className={`${props.className || ""}  mobile-margin section-container pool-container`}>
      <div className="app-card-white app-pool-overview-card flex-row justify-between align-center">
        <div className="app-pool-overview-card-title flex row justify-start align-center">
          <img src={ALPIcon} alt="ALP" />
          <div>ALP</div>
        </div>
        <DetailSection title="Price" content={!marketLoading ? `$${formatNumber(marketInfo.alpPrice, 4)}` : undefined} />
        <DetailSection title="Total Supply" content={!marketLoading ? `${formatNumber(marketInfo.alpSupply)} ALP` : undefined} />
        <DetailSection title="Market Cap" content={!marketLoading ? `$${formatNumber(marketInfo.marketCap)}` : undefined} />
        <DetailSection data={`ALP receives 100% of the protocol revenue, and the APR calculation is based on a 7-day average. Base: ${formatNumber((marketInfo.apr || 0) * 100)}%. Extra staking rewards: ${formatNumber(stakeApr)}%`} stakeAPR={formatNumber(stakeApr)} title="APR" className="pool-apr" content={!marketLoading ? `${formatNumber((marketInfo.apr || 0) * 100 + stakeApr)}%` : undefined} />
        <div className='app-pool-overview-more'>
          <img src={Network} alt='' />
          <div />
        </div>
      </div>
      <div className='app-pool-hint flex-row justify-center align-center'>
        Mint ALP Tokens to earn fees from swaps and leverage tradings.
        &nbsp;
        <a href='https://abex-protocol.gitbook.io/abex/for-lps/provide-liquidity/alp-liquidity-token' target='_blank' rel='noreferrer'>Learn more</a>
      </div>
      <div className="app-stake-container">
        <div className="app-stake-info">
          <div>
            <p className="app-stake-title">Stake To Earn Reward</p>
            <div className="app-stake-text">
              You can stake ALP to receive SUI as the reward. The amount of SUI you receive is determined by your share in the Stake Pool, and rewards are settled every second.              <br />
            </div>
          </div>
          <div>
            <p className="app-stake-portfolio">
              Portfolio
            </p>
            <div className="app-stake-values">
              <div className="app-stake-value-item">
                <div className="app-stake-value-title">Unstaked</div>
                <div className="app-stake-value">
                  {formatNumber(alpBalance)} ALP
                </div>
              </div>
              <div className="app-stake-divider"></div>
              <div className="app-stake-value-item">
                <div className="app-stake-value-title">Staked</div>
                <div className="app-stake-value">
                  {formatNumber(stakedBalance)} ALP
                </div>
              </div>
              <div className="app-stake-divider"></div>
              <div className="app-stake-value-item">
                <div className="app-stake-value-title">Claimable rewards</div>
                <div className="app-stake-value flex-row">
                  <span>{staked?.claimable ? formatNumber(Number(staked.claimable) / (10 ** consts.coins['sui'].decimals), 2) : '0'} SUI</span>

                  <Button buttonStyle={"main"} text={"Claim"} onClick={() => {
                    unstakeALP(true)
                  }} className="app-stake-claim" isLoading={false} />
                </div>

              </div>

              {/* <div className="app-stake-divider"></div>
              <div className="app-stake-value-item">
                <div className="app-stake-value-title">Reward</div>
                <div className="app-stake-value">--</div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="app-stake-wrap">
          <div>
            <div className="app-stake-switch-group">
              <div className={`app-stake-switch ${isStake ? 'active' : ''}`} onClick={() => setIsStake(true)}>Stake</div>
              <div className={`app-stake-switch ${!isStake ? 'active' : ''}`} onClick={() => setIsStake(false)}>Unstake</div>
            </div>
            <div className="app-stake-input-container">
              <div className="app-stake-input-title flex justify-between">
                <span>{isStake ? 'Stake: ' : 'Unstake: '}</span>
                <span>Balance: {isStake ? alpBalance : stakedBalance}</span>
              </div>
              <div className="app-stake-input-wrap flex justify-between">
                <input type="text" value={stakeAmount || ""} placeholder="0" onChange={(e) => handleInput(e.target.value)} />
                <div className="flex items-center">
                  <span className="max-btn" onClick={() => {
                    if (isStake) {
                      setStakeAmount(alpBalance)
                    } else {
                      setStakeAmount(stakedBalance)
                    }
                  }}>Max</span>
                  <span>ALP</span>
                </div>
              </div>
            </div>
          </div>
          <Button
            buttonStyle={invalidStake ? 'disabled' : "main"}
            text={invalidStake ? invalidStake : isStake ? 'Stake' : 'Unstake'}
            onClick={isStake ? stakeALP : unstakeALP}
            isLoading={isSubmitLoading}
            className="app-pool-action-button"
          />
        </div>
      </div>
      <div className="app-card-white flex app-pool-trade-container">
        <div className='app-pool-trade-desc'>
          <div className={`app-desc-wrap ${slideIndex === 0 ? 'alp-chart-container' : ''}`}>
            <Swiper
              modules={[Autoplay, Navigation, Pagination]}
              loop
              navigation
              autoplay={{
                delay: 10000,
                disableOnInteraction: false,
              }}
              pagination={{ clickable: true }}
              onSlideChange={(swiper: SwiperClass) => { setSlideIndex(swiper.realIndex) }}
            >
              <SwiperSlide>
                <div className="slide-item mt-24 mb-12">
                  <div className="flex items-center alp-price">
                    <img src={getALPData.icon} className={`${getALPData.diffPrice.includes('-') ? 'rotate-180' : ''}`} alt="" />
                    <div className="ml-8 text-20 font-700 mr-32">ALP Price (USD)</div>
                    <div className="text-20 font-700 mr-32">
                      {
                        marketLoading ? <Skeleton width={60} /> : formatNumber(marketInfo.alpPrice, 4)
                      }
                    </div>
                    <div className={`${getALPData.className} text-12 font-500`}>
                      {
                        marketLoading ? <Skeleton width={80} height={32} /> : (
                        <>
                          <div>{getALPData.percentage}</div>
                          <div>{getALPData.diffPrice}</div>
                        </>)
                      }
                    </div>
                  </div>
                  <div className="flex items-center mt-16">
                    <div className={`chart-time mr-8 ${chartTime === 1 ? 'active' : ''}`} onClick={() => handleSetChartTime(1)}>1 Day</div>
                    <div className={`chart-time mr-8 ${chartTime === 2 ? 'active' : ''}`} onClick={() => handleSetChartTime(2)}>1 Week</div>
                    <div className={`chart-time mr-8 ${chartTime === 3 ? 'active' : ''}`} onClick={() => handleSetChartTime(3)}>1 Month</div>
                    <div className={`chart-time mr-8 ${chartTime === 4 ? 'active' : ''}`} onClick={() => handleSetChartTime(4)}>3 Month</div>
                    <div className={`chart-time ${chartTime === 5 ? 'active' : ''}`} onClick={() => handleSetChartTime(5)}>6 Month</div>
                  </div>
                  <div className="chart-box mt-28" key={chartWidth}>
                    <AreaChart width={chartWidth} height={chartWidth === 870 ? 220 : 410} data={chartData} >
                      <defs>
                        <linearGradient id="colorPrice" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="#34C85A" stopOpacity={0.48}/>
                          <stop offset="95%" stopColor="#34C85A" stopOpacity={0}/>
                        </linearGradient>
                      </defs>
                      <XAxis dataKey="timestamp" hide />
                      <YAxis axisLine={false} domain={['dataMin', 'dataMax']} tickLine={false} orientation="right" tickFormatter={(tick) => `$${tick.toString() ? tick.toString().match(/^\d+(?:\.\d{0,4})?/) : '0'}`} />
                      <Tooltip formatter={(tick) => `$${tick.toString() ? tick.toString().match(/^\d+(?:\.\d{0,4})?/) : '0'}`} labelFormatter={(tick) => `time: ${dayjs.unix(tick).format('MM-DD HH:mm')}`} />
                      <Area type="monotone" dataKey="value" name="price" stroke="#34C85A" strokeWidth={2} fillOpacity={1} fill="url(#colorPrice)" />
                    </AreaChart>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='flex mt-48'>
                  <img className="item-img" src={ALPIcon} alt="ALP" width={48} height={48} />
                  <div>
                    <h2 className='ml-8'>Introduce ALP</h2>
                    <div className='ml-8 desc-subtitle'>Basic information about ALP</div>
                  </div>
                </div>
                <div className="slide-item flex align-center justify-between">
                  <div>
                    <p>
                      ALP is a synthetic asset
                      <br />
                      Designed to provide liquidity to traders on ABEx.
                    </p>
                    <p>
                      The quantity of ALP you hold represents
                      <br />
                      Your share in the liquidity pool.
                    </p>
                    <p>
                      Currently, ALP is composed of three asset types:
                      <br />
                      USDC, USDT and SUI.
                    </p>
                  </div>
                  <img className="item-img" src={Pool1} alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='flex mt-48'>
                  <img className="item-img" src={ALPIcon} alt="ALP" width={48} height={48} />
                  <div>
                    <h2 className='ml-8'>Introduce ALP</h2>
                    <div className='ml-8 desc-subtitle'>Income from holding ALP</div>
                  </div>
                </div>
                <div className="slide-item flex align-center justify-between">
                  <div>
                    <p>
                      Holding ALP entitles you to 100% of
                      <br />
                      ABEx protocol income
                    </p>
                    <p>
                      ABEx protocol income including:
                      <br />
                      leverage trading fees, Reserving Fee, and Swap fees.
                    </p>
                  </div>
                  <img className="item-img" src={Pool2} alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='flex mt-48'>
                  <img className="item-img" src={ALPIcon} alt="ALP" width={48} height={48} />
                  <div>
                    <h2 className='ml-8'>Introduce ALP</h2>
                    <div className='ml-8 desc-subtitle'>Regarding ALP price fluctuations</div>
                  </div>
                </div>
                <div className="slide-item flex align-center justify-between">
                  <div>
                    <p>
                      The initial price of ALP is $1
                    </p>
                    <p>
                      The price of ALP  is primarily influenced by factors such as
                      <br />
                      The fluctuation in the underlying asset prices
                      <br />
                      Trader profits and losses
                      <br />
                      The total income generated by the ABEx protocol.
                    </p>
                  </div>
                  <img className="item-img" src={Pool3} alt="" />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="app-pool-swiper-actions"></div>
        </div>
        <div className='app-pool-trade-action'>
          <div className='app-pool-action-tab flex-row'>
            <div
              className={`app-pool-action-tab-item ${mode === 'buy' ? 'app-pool-action-tab-item-active' : ''}`}
              onClick={() => {
                setMode('buy')
                clearStates('buy')
              }}
            >Buy ALP</div>
            <div
              className={`app-pool-action-tab-item ${mode === 'sell' ? 'app-pool-action-tab-item-active' : ''}`}
              onClick={() => {
                setMode('sell')
                clearStates('sell')
              }}
            >Sell ALP</div>
          </div>
          <div className='app-pool-action-input-title'>{mode === 'buy' ? 'Deposit' : 'Withdraw'}</div>
          <TokenInput
            token={mode === 'buy' ? token : TOKENS.filter(x => x.symbol === 'alp')[0]}
            titlePrefix={"Pay"}
            amount={amount}
            onChange={setAmount}
            onChangeToken={setToken}
            usdValue={mode === 'buy' ? formatNumber(tokenPrice[token.symbol] * (amount || 0)) : formatNumber(marketInfo.alpPrice * (amount || 0))}
            tokenList={mode === 'buy' ? inputTokens : undefined}
            tokenEditable={mode === 'buy'}
            showBalance={true}
            balance={accountData.balance.length ? (mode === 'buy' ? accountData.balance.filter(e => e.symbol === token.symbol)[0]?.balance || 0 : accountData.balance.filter(e => e.symbol === 'alp')[0]?.balance || 0) : 0}
            showMax={true}
            onMax={() => {
              if (mode === 'buy') {
                let balance = accountData.balance.filter(e => e.symbol === token.symbol)[0].balance
                if (token.symbol === 'sui') {
                  balance -= POOL_OP_MIN_FEE
                  balance = balance < 0 ? 0 : balance
                }
                setAmount(balance)
              } else {
                setAmount(accountData.balance.filter(e => e.symbol === 'alp')[0].balance)
              }
            }}
          />
          <div className='app-pool-action-input-title'>Receive</div>
          <TokenInput
            token={mode === 'sell' ? token : TOKENS.filter(x => x.symbol === 'alp')[0]}
            titlePrefix={"Receive"}
            amount={secondAmount ? formatNumber(secondAmount * (1 - (rebaseRate || 0)), 4) : null}
            onChangeToken={setToken}
            usdValue={mode === 'buy' ? formatNumber(marketInfo.alpPrice * (secondAmount || 0) * (1 - (rebaseRate || 0))) : formatNumber(tokenPrice[token.symbol] * (secondAmount || 0) * (1 - (rebaseRate || 0)))}
            editable={false}
            tokenEditable={mode === 'sell'}
            tokenList={mode === 'sell' ? inputTokens : undefined}
            showBalance={true}
            balance={accountData.balance.length ? (mode === 'sell' ? accountData.balance.filter(e => e.symbol === token.symbol)[0]?.balance || 0 : accountData.balance.filter(e => e.symbol === 'alp')[0]?.balance) || 0 : 0}
          />
          {/* <div className='app-pool-action-hint flex-row justify-between align-center'>
            <div>
              One step stake ALP
            </div>
            <label className="switch">
              <input
                type="checkbox"
                checked={isOneStep}
                onChange={() => setIsOneStep(!isOneStep)}
              />
              <span className="slider"></span>
            </label>
          </div> */}

          <div className='app-pool-action-hint flex-row justify-between align-center'>
            <div>
              Fees
            </div>
            <div data-rh="Fees for buying / selling ALP will vary based on which assets the index has less or more, each asset might be different.">
              {rebaseRateLoading ? <Skeleton width={80} /> : `${formatNumber((rebaseRate || 0) * 100, 4)}%`}
            </div>
          </div>

          <Button
            buttonStyle={invalid ? 'disabled' : "main"}
            text={invalid ? invalid : `${mode === 'buy' ? 'Buy' : 'Sell'} ALP`}
            onClick={mode === 'buy' ? buyALP : sellALP}
            isLoading={isSubmitLoading}
            className="app-pool-action-button"
          />
        </div>
      </div>
      <div className="app-pool-overview">
        <div className='mb-16 flex-row justify-start align-center'>
          <img src={PoolIcon} alt="" />
          <div className="app-pool-title">Pool Overview</div>
        </div>
        <Table titles={miningTableTitle} rows={Object.keys(consts.abexCore.vaults).map(e => {
          return [
            localSymbolToOriginal(e),
            !priceLoading ? `$${priceToString(tokenPrice[e])}` : <Skeleton width={50} />,
            !vaultInfoLoading ? `${formatNumber(vaultInfoMap[e]?.liquidity / (10 ** consts.coins[e].decimals), 2)}` : <Skeleton width={50} />,
            !vaultInfoLoading ? `${formatNumber(vaultInfoMap[e]?.reservedAmount / (10 ** consts.coins[e].decimals), 2)}` : <Skeleton width={50} />,
            `${parseInt(consts.abexCore.vaults[e].weight) / totalWeight * 100}%`,
            !vaultInfoLoading ? `${formatNumber((vaultInfoMap[e]?.liquidity + vaultInfoMap[e]?.reservedAmount) * tokenPrice[e] * 100 / (10 ** consts.coins[e].decimals * marketInfo.marketCap), 2)}%` : <Skeleton width={50} />,
            !vaultInfoLoading ? `${formatNumber(vaultInfoMap[e]?.reservedAmount * 100 / (vaultInfoMap[e]?.liquidity + vaultInfoMap[e]?.reservedAmount), 2)}%` : <Skeleton width={50} />,
          ]
        })} />
      </div>
    </div>
  );
};

export default Pool;