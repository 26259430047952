import { useWallet } from "@suiet/wallet-kit";
import React, { useEffect } from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import "./App.css";
import Header from "./components/header";
import '@suiet/wallet-kit/style.css';
import { SnackbarProvider } from 'notistack'
import { useNetwork } from "../hooks/useNetwork";
import { getConsts } from "abex-ts-sdk";
import { IAccountData, useAccountData } from "../hooks/useAccountData";
import { useTokenPrice } from "../hooks/useTokenPrice";
import Footer from "./components/Footer";

type ContextType = {
  wallet: ReturnType<typeof useWallet>,
  network: ReturnType<typeof useNetwork>,
  consts: ReturnType<typeof getConsts>,
  accountData: IAccountData,
  refreshAccount: () => void,
  prices: { [key: string]: number },
  priceLoading: boolean,
};


const App: React.FC = () => {
  const wallet = useWallet()
  const network = useNetwork()
  const consts = getConsts(network)
  const { accountData, refresh: refreshAccount } = useAccountData(wallet, network, consts)
  const { tokenPrice: prices, isLoading: priceLoading } = useTokenPrice(network)

  // useEffect(() => {
  //   setInterval(refreshAccount, 10000)
  // }, [])

  return (
    <SnackbarProvider>
      <div className="global-container flex-col align-center justify-start app-container">
        <Header />
        <Outlet context={{ wallet, network, consts, accountData, refreshAccount, prices, priceLoading }} />
      </div>
      <Footer />
    </SnackbarProvider>
  )
}

export function useEssential() {
  return useOutletContext<ContextType>();
}

export default App;