import { API, DataAPI, joinSymbol } from "abex-ts-sdk"
import { useEffect, useState } from "react"
import { getProvider } from "../app/utils"
import { SUI_CLOCK_OBJECT_ID } from "@mysten/sui.js";

export function useFundingRate(indexToken: string, network: string) {
  const [fundingRateMap, setFundingRateMap] = useState<{[key: string]: number}>({})
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!indexToken) return
    setIsLoading(true);
    const dataAPI = API.getInstance(network, getProvider(network))
    Promise.all([
      dataAPI.fundingFeeRate(indexToken, true).then((fundingRate: number) => {
        const symbol = joinSymbol('long', indexToken)
        setFundingRateMap(prevMap => ({
          ...prevMap,
          [symbol]: fundingRate,
        }))
      }),
      dataAPI.fundingFeeRate(indexToken, false).then((fundingRate: number) => {
        const symbol = joinSymbol('short', indexToken)
        setFundingRateMap(prevMap => ({
          ...prevMap,
          [symbol]: fundingRate,
        }))
      }),
    ]).then(() => {
      setIsLoading(false)
    }).catch((err) => {
      console.error(err.message)
      setError(err.message)
      setIsLoading(false)
    })
    
  }, [indexToken, network]);

  return {
    fundingRateMap,
    isLoading,
    error,
  }
}
