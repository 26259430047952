import React, { useState, useMemo, useEffect } from "react";
import { LONG_COLOR, SHORT_COLOR, SWAP_COLOR, TOKENS, PERP_OP_MIN_FEE, SWAP_OP_MIN_FEE, RELAYER_FEE } from '../constants';
import Leverage from "./Leverage";
import TokenInput from "./TokenInput";
import "./TradeAction.css";
import { formatNumber, formatNumberWithLeadingZeroInfo, localSymbolToOriginal, priceToString } from '../utils';
import { IAccountData } from "../../hooks/useAccountData";
import Button from "./Button";
import SettingModal from '../components/Setting'
import Skeleton from "react-loading-skeleton";
import InfoList from "./InfoList";
import TextSwitch from "./TextSwitch"
import PendingOrderSettings from "./PendingOrderSettings"
import Setting from "../../assets/setting.svg";
import PythIcon from "../../assets/pyth.svg";
import { IConsts, IPositionConfig } from "abex-ts-sdk";

type Props = {
  className?: string;
  collateralTokenList: string[];
  indexTokenList: string[];
  indexToken: string;
  setIndexToken: (token: string) => void;
  mode: "long" | "short" | "swap";
  setMode: (mode: "long" | "short" | "swap") => void;
  collateralToken: string;
  setCollateralToken: (token: string) => void;
  prices: { [key: string]: number };
  payAmount: number | null;
  setPayAmount: (amount: number | null) => void;
  leverage: number;
  setLeverage: (leverage: number) => void;
  secondAmount: number | null;
  setSecondAmount: (amount: number | null) => void;
  setTypeOnFirst: (typeOnFirst: boolean) => void;
  clearStates: () => void;
  openPosition: () => void;
  accountData: IAccountData;
  swapFromToken: string;
  swapToToken: string;
  setSwapFromToken: (token: string) => void;
  setSwapToToken: (token: string) => void;
  maxLeverage?: number;
  openFee?: number;
  confirmSwap: () => void;
  reservingRateLoading: boolean;
  vaultInfoLoading: boolean;
  swapFee?: number;
  reservingRate?: number;
  availableReserve?: number;
  swapTokenList: string[];
  orderTab: string;
  setOrderTab: (tab: string) => void;
  indexPrice: number | null;
  setIndexPrice: (price: number | null) => void;
  positionConfig: IPositionConfig;
  consts: IConsts;
}

const TradeAction: React.FC<Props> = ({
  className,
  collateralTokenList,
  indexTokenList,
  indexToken,
  setIndexToken,
  mode,
  setMode,
  collateralToken,
  setCollateralToken,
  prices,
  payAmount,
  setPayAmount,
  leverage,
  setLeverage,
  secondAmount,
  setSecondAmount,
  setTypeOnFirst,
  clearStates,
  openPosition,
  accountData,
  maxLeverage,
  swapFromToken,
  swapToToken,
  setSwapFromToken,
  setSwapToToken,
  confirmSwap,
  reservingRateLoading,
  vaultInfoLoading,
  swapTokenList,
  openFee = 0,
  swapFee = 0,
  reservingRate = 0,
  availableReserve = 0,
  orderTab,
  setOrderTab,
  indexPrice,
  setIndexPrice,
  positionConfig,
  consts,
}) => {
  const [isSwitch, setSwitch] = useState(false)
  const [openSetModal, setOpenSetModal] = useState(false)
  const [getProfitValue, setProfitValue] = useState<number | null>(null)
  const [getLossValue, setLossValue] = useState<number | null>(null)
  let actionDetail: JSX.Element = <div />;
  const usedReserve = (payAmount || 0 - openFee) * Math.min((leverage || 0), (positionConfig?.maxReservedMultiplier || 0))

  useEffect(() => {
    setLeverage(10);
  }, [indexToken])

  const checkVaild = () => {
    if (!accountData.address) {
      return 'Please connect wallet'
    }
    if (!payAmount) {
      return 'Invalid Pay Amount'
    }
    if (mode !== 'swap' && positionConfig.minCollateralValue > payAmount * prices[collateralToken] / (1 + positionConfig.openFeeBps * leverage)) {
      return 'Pay Amount Too Small'
    }
    if (mode !== 'swap' && payAmount > (accountData.balance.filter(b => b.symbol === collateralToken)[0]?.balance || 0)) {
      return 'Insufficient Balance'
    }
    if (mode === 'swap' && payAmount > (accountData.balance.filter(b => b.symbol === swapFromToken)[0]?.balance || 0)) {
      return 'Insufficient Balance'
    }
    if (mode !== 'swap' && usedReserve > availableReserve / (10 ** consts.coins[collateralToken].decimals)) {
      return 'Insufficient Reserve'
    }
    return false
  }

  const invalid = checkVaild()
  if (mode === "long" || mode === 'short') {
    const listContent: [string, string | number | JSX.Element, string?][] = [
      ["Collateral in", localSymbolToOriginal(collateralToken)],
      ["Leverage", leverage],
      ["Avail. Reserve", vaultInfoLoading ? <Skeleton width={80} /> : `${formatNumber(usedReserve)}/${formatNumber(availableReserve / (10 ** consts.coins[collateralToken].decimals))} ${localSymbolToOriginal(collateralToken)}`, 'The maximum profit user will receive for the position / Total reserve can be used for opening new positions.'],
      ["Reserving Fee Rate", reservingRateLoading ? <Skeleton width={80} /> : `${formatNumberWithLeadingZeroInfo(reservingRate * 100)}%`, 'Traders pay interest to LPs based on the reserve of the position in 8 hours.'],
      ["Entry Price", priceToString(orderTab === 'market' ? prices[indexToken] : (indexPrice || 0))],
      ["Est. Liq. Price", priceToString((orderTab === 'market' ? prices[indexToken] : (indexPrice || 0)) * (mode === 'long' ? (1 - 1 / leverage) : (1 + 1 / leverage)))],
      ["Open Fee", `${formatNumber(openFee)} ${localSymbolToOriginal(collateralToken)} (${formatNumberWithLeadingZeroInfo(prices[collateralToken] * openFee)})`],
    ]
    if (orderTab === 'limit') {
      listContent.push(['Relayer Fee', `${RELAYER_FEE} SUI`])
    }
    actionDetail = (<div className="flex-col app-trade-action-detail align-start">
      <TokenInput
        titlePrefix="Pay"
        token={TOKENS.filter(t => t.symbol === collateralToken)[0]}
        amount={payAmount}
        usdValue={formatNumber(prices[collateralToken] * (payAmount || 0))}
        onChange={e => {
          setPayAmount(e)
          setTypeOnFirst(true)
        }}
        tokenList={TOKENS.filter(t => collateralTokenList.includes(t.symbol))}
        onChangeToken={t => {
          setCollateralToken(t.symbol)
          setTypeOnFirst(true)
          setPayAmount(0)
          setSecondAmount(0)
        }}
        showBalance={true}
        balance={accountData.balance.filter(b => b.symbol === collateralToken)[0]?.balance || 0}
        showMax={true}
        onMax={() => {
          let balance = accountData.balance.filter(b => b.symbol === collateralToken)[0]?.balance || 0
          // FIXME: remove this hack
          if (collateralToken === 'sui') {
            balance = balance - PERP_OP_MIN_FEE
            balance = balance < 0 ? 0 : balance
          }
          setPayAmount(balance)
          setTypeOnFirst(true)
        }}
      />
      <div className="app-trade-action-switch-token">
        {/*         <div className="app-trade-action-switch-token-arrow" />
*/}      </div>
      <TokenInput
        titlePrefix={mode === 'long' ? 'Long' : 'Short'}
        token={TOKENS.filter(t => t.symbol === indexToken)[0]}
        amount={secondAmount}
        usdValue={formatNumber(prices[indexToken] * (secondAmount || 0))}
        onChange={e => {
          setSecondAmount(e)
          setTypeOnFirst(false)
        }}
        tokenList={TOKENS.filter(t => indexTokenList.includes(t.symbol))}
        onChangeToken={t => {
          setIndexToken(t.symbol)
          setTypeOnFirst(false)
          setPayAmount(0)
          setSecondAmount(0)
        }}
        showLeverage={true}
        // showMax={!IsShowBalance}
        // showSolidMax={IsShowBalance}
        leverage={leverage}
      />

      {orderTab === 'limit' && <TokenInput
        titlePrefix="Price"
        isShowColon={false}
        tokenEditable={false}
        token={TOKENS.filter(t => t.symbol === 'usdt')[0]}
        amount={indexPrice}

        showMark={true}
        mark={parseFloat(formatNumber(prices[indexToken], 2, false))}
        onChange={e => {
          setIndexPrice(e)
        }}
      />}
      <Leverage
        leverage={leverage}
        onChange={setLeverage}
        max={maxLeverage}
        color={mode === 'long' ? LONG_COLOR : SHORT_COLOR}
      />
      {/*       <TextSwitch title="Stop Loss/Take Profit" className={'mt-16'} onChange={e => { setSwitch(e) }} />
      {isSwitch && <PendingOrderSettings isProfit={true} title='Take Profit' token='FSUI' amount={1000000} onChange={e => { setProfitValue(e) }} />}
      {isSwitch && <PendingOrderSettings isLoss={true} title='Stop Loss' token='FSUI' amount={1000000} onChange={e => { setLossValue(e) }} />} */}

      <InfoList
        className="app-trade-action-detail-msg"
        content={listContent}
      />
      <Button
        buttonStyle={invalid ? 'disabled' : mode}
        text={invalid ? invalid.toString() : (orderTab === 'market' ? `Market ${mode === 'long' ? 'Long' : 'Short'}` : 'Place Order')}
        onClick={() => {
          if (invalid) {
            return
          }
          openPosition()
        }}
        className="app-trade-action-detail-btn"
        isLoading={false}
      />
    </div>);
  } else if (mode === "swap") {
    actionDetail = (
      <div className="flex-col app-trade-action-detail">
        <TokenInput
          titlePrefix="Pay"
          token={TOKENS.filter(t => t.symbol === swapFromToken)[0]}
          amount={payAmount}
          usdValue={formatNumber(prices[swapFromToken] * (payAmount || 0))}
          onChange={e => {
            setPayAmount(e)
            setTypeOnFirst(true)
          }}
          tokenList={TOKENS.filter(t => swapTokenList.includes(t.symbol))}
          onChangeToken={t => {
            if (t.symbol === swapToToken) {
              setSwapToToken(swapFromToken)
            }
            setTypeOnFirst(true)
            setSwapFromToken(t.symbol)
            setPayAmount(0)
            setSecondAmount(0)
          }}
          showBalance={true}
          balance={accountData.balance.filter(b => b.symbol === swapFromToken)[0]?.balance || 0}
          showMax={true}
          onMax={() => {
            let balance = accountData.balance.filter(b => b.symbol === swapFromToken)[0]?.balance || 0
            // FIXME: remove this hack
            if (swapFromToken === 'sui') {
              balance = balance - SWAP_OP_MIN_FEE
              balance = balance < 0 ? 0 : balance
            }
            setPayAmount(balance)
            setTypeOnFirst(true)
          }}
        />
        <div className="app-trade-action-switch-token">
          {/*         <div className="app-trade-action-switch-token-arrow" />
 */}      </div>
        <TokenInput
          titlePrefix={'Receive'}
          token={TOKENS.filter(t => t.symbol === swapToToken)[0]}
          amount={(secondAmount || 0) - swapFee}
          usdValue={formatNumber(prices[swapToToken] * ((secondAmount || 0) - swapFee))}
          // onChange={e => {
          //   setSecondAmount(e)
          //   setTypeOnFirst(false)
          // }}
          tokenList={TOKENS.filter(t => swapTokenList.includes(t.symbol))}
          onChangeToken={t => {
            if (t.symbol === swapFromToken) {
              setSwapFromToken(swapToToken)
            }
            setSwapToToken(t.symbol)
            setTypeOnFirst(false)
            setPayAmount(0)
            setSecondAmount(0)
          }}
          showBalance={true}
          balance={accountData.balance.filter(b => b.symbol === swapToToken)[0]?.balance || 0}
          // showMax={true}
          // onMax={() => {
          //   let balance = accountData.balance.filter(b => b.symbol === swapToToken)[0]?.balance || 0
          //   // FIXME: remove this hack
          //   if (swapToToken === 'sui') {
          //     balance = balance - 0.22
          //     balance = balance < 0 ? 0 : balance
          //   }
          //   setSecondAmount(balance)
          //   setTypeOnFirst(false)
          // }}
          editable={false} // Temp disable
        />
        <div className="flex-col app-trade-action-detail-msg">
          <div className="flex-row justify-between">
            <div className="app-trade-action-detail-msg-title">Fee</div>
            <div className="app-trade-action-detail-msg-value">{formatNumber(swapFee)} {localSymbolToOriginal(swapToToken)}</div>
          </div>
        </div>
        <Button
          buttonStyle={invalid ? 'disabled' : 'main'}
          text={invalid ? invalid.toString() : `Swap`}
          onClick={() => {
            if (invalid) {
              return
            }
            confirmSwap()
          }}
          className="app-trade-action-detail-btn"
          isLoading={false}
        />
      </div>
    );
  }
  return (
    <div className={`${className} flex-col justify-start app-card-white app-trade-action`}>
      <div className='app-trade-action-tab flex justify-between'>
        <div
          className={`app-trade-action-tab-item ${mode === 'long' ? 'app-trade-action-tab-item-active color-green-text' : ''} ${mode === 'swap' ? 'app-trade-action-tab-item-right-line' : ''}`}
          onClick={() => {
            setMode('long')
            clearStates()
          }}
        >Long</div>
        <div
          className={`app-trade-action-tab-item ${mode === 'short' ? 'app-trade-action-tab-item-active color-red-text' : ''}`}

          onClick={() => {
            setMode('short')
            clearStates()
          }}
        >Short</div>
        <div
          className={`app-trade-action-tab-item ${mode === 'swap' ? 'app-trade-action-tab-item-active color-blue-text' : ''} ${mode === 'long' ? 'app-trade-action-tab-item-left-line' : ''}`}

          onClick={() => {
            setMode('swap')
            setSwapFromToken('sui')
            setSwapToToken('usdt')
            setLeverage(1)
            clearStates()
          }}
        >Swap</div>
      </div>
      <div className="flex mt-16 justify-between">
        <div className='app-trade-action-tab2 align-center'>
          <div className={`app-trade-action-tab2-item ${orderTab === 'market' && 'app-trade-action-tab2-item-active'}`} onClick={() => {
            setOrderTab('market')
            clearStates()
          }}>Market</div>
          <div className={`app-trade-action-tab2-item ${orderTab === 'limit' && 'app-trade-action-tab2-item-active'}`} onClick={() => {
            setOrderTab('limit')
            clearStates()
          }}>Limit</div>
        </div>
        <div onClick={() => { setOpenSetModal(true) }} className="flex items-center w-24 h-24 rounded-24 app-trade-action-setting justify-center cursor-pointer">
          <img src={Setting} alt="" width={16} height={16} />
        </div>
      </div>
      <SettingModal setShowSettingOpen={setOpenSetModal} showSettingOpen={openSetModal}></SettingModal>
      {actionDetail}
      <div className="pyth-image">
        <img src={PythIcon} alt="Pyth" />
      </div>
    </div>
  );
};

export default TradeAction;
