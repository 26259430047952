import React, { useEffect, useRef } from 'react';
import "./header.css";
import LogoHeader from "../../assets/logo-header.svg";
import useIsSafari from '../../hooks/useIsSafari';

type Props = {
  className?: string;
  ref?: any;
  setShowFollow: Function;
};

const Header: React.FC<Props> = (props) => {
  const topBarRef = useRef<HTMLDivElement>(null);
  const topBarCardRef = useRef<HTMLDivElement>(null);
  const isSafari = useIsSafari()
  useEffect(() => {
    window.addEventListener('scroll', function() {
      if (window.scrollY > 90) {
        if (topBarRef.current && topBarCardRef.current) {
          topBarRef.current.classList.add('header-section-fixed');
          topBarCardRef.current.classList.add('header-card-remove')
          if (isSafari) {
            topBarRef.current.classList.add('header-section-fixed-safari');
          }
        }
      } else {
        if (topBarRef.current && topBarCardRef.current) {
          topBarRef.current.classList.remove('header-section-fixed');
          topBarCardRef.current.classList.remove('header-card-remove')
          if (isSafari) {
            topBarRef.current.classList.remove('header-section-fixed-safari');
          }
        }
      }
    });
  }, [isSafari]);
  const onClick = () => {
    props.setShowFollow(true)
  }
  return (
    <div className={props.className} ref={topBarRef}>
      <div className='header-card card-max-white flex-row align-center justify-between' ref={topBarCardRef}>
        <img src={LogoHeader} style={{height: 30, width: 113}} alt='' />
        <div className='header-sidebar flex-row justify-end align-center'>
          <span onClick={onClick}>Governance</span>
          <span onClick={onClick}>About</span>
          <a href='/app/trade'><div className='launch-icon trade-icon flex-row justify-center align-center'>Trade</div></a>
        </div>
      </div>
    </div>
  );
};

export default Header;