import React, { useEffect, useState } from 'react';
import Chart from './components/Chart';
import DetailSection from './components/DetailSection';
import TradeAction from './components/TradeAction';
import OrderCancel from './components/OrderCancel';
import "./Trade.css";
import Table from './components/Table';
import TokenAmount from './components/TokenAmount';
import PnL from './components/PnL';
import { DEFAULT_COLLATERAL_TOKEN, DEFAULT_INDEX_TOKEN, DEFAULT_LEVERAGE, DEFAULT_SWAP_FROM_TOKEN, DEFAULT_SWAP_TO_TOKEN, LONG_COLOR, OFFLINE_TOKEN, SHORT_COLOR, TOKENS } from './constants';
import { formatNumber, formatNumberWithLeadingZeroInfo, localSymbolToOriginal, priceToString, upperFirstCharacter } from './utils';
import { usePosition } from '../hooks/usePosition';
import useCryptoPriceChange from '../hooks/useCryptoPriceChange';
import PairDropdown from './components/PairDropdown';
import { useEssential } from './App';
import PositionAdjust from './components/PositionAdjust';
import { IOrderInfo, IPositionInfo, joinSymbol, parseSymbolKey } from 'abex-ts-sdk';
import PositionOpen from './components/PositionOpen';
import PositionClose from './components/PositionClose';
import { usePositionConfig } from '../hooks/usePositionConfig';
import SwapConfirm from './components/SwapConfirm';
import { useFundingRate } from '../hooks/useFundingRate';
import { useReservingRate } from '../hooks/useReservingRate';
import { calculateLiquidationPrice, calculatePNL, getRealLeverage, getSwapAmountAndFee } from './math';
import { useRebaseRate } from '../hooks/useRebaseRate';
import { useVaultInfo } from '../hooks/useVaultInfo';
import UsdcSvg from "../assets/tokens/usdc.svg";
import TipsIcon from "../assets/tips.svg";
import NoticeSvg from "../assets/group.svg";
import StableSvg from '../assets/stable.svg'
import WaveSvg from '../assets/wave.svg'
import MobileCloseSvg from "../assets/mobile-close.svg";
import SuiSvg from "../assets/sui.svg";
import { useOrder } from '../hooks/useOrder';
import useSvgIcon from '../hooks/useSvgIcon';
import { DisplayPosition } from './models';
import { useHistories } from '../hooks/useHistories';
import { enqueueSnackbar } from 'notistack';
import PositionTable from './components/Mobile/Position';
import Share from './components/Share';
import { useQueryParam } from '../hooks/useQueryParam';

type Props = {
  className?: string;
};


const posistionTableTitles = [
  'Symbol',
  'Size',
  'Collateral',
  'Entry Price',
  'Mark Price',
  `Est.liq.Price <img src=${TipsIcon} class="ml-2 cursor-pointer" data-rh="When your collateral is non-stablecoin assets, the liquidation price will change in real-time based on the price fluctuations of the collateral assets." />`,
  'Unrealized PNL',
  'Adjust',
  'Close',
  'Share',
]
const orderTableTitles = [
  'Type',
  'Order',
  'Price',
  'Mark Price',
  'Cancel',
]
const historyTableTitles = [
  'Date',
  'Action',
  'Price',
  'Volume',
  'Fee',
  'PnL',
  'View Tx',
]
const Trade: React.FC<Props> = (props) => {
  const { wallet, network, consts, accountData, refreshAccount, prices, priceLoading } = useEssential()
  const [mode, setMode] = useState<"long" | "short" | "swap">("long");
  const indexTokenList = Object.keys(consts.abexCore.symbols).filter(x => x.startsWith('long') === (mode === 'long')).map(x => parseSymbolKey(x)[1])
  const [indexToken, setIndexToken] = useState(DEFAULT_INDEX_TOKEN);
  const supportCollateralTokens = consts.abexCore.symbols[`${mode === 'long' ? 'long' : 'short'}${upperFirstCharacter(indexToken)}`].supportedCollaterals
  const [leverage, setLeverage] = useState(DEFAULT_LEVERAGE);
  const [collateralToken, setCollateralToken] = useState(DEFAULT_COLLATERAL_TOKEN);
  const [payAmount, setPayAmount] = useState<number | null>(null);
  const [secondAmount, setSecondAmount] = useState<number | null>(null);
  const [typeOnFirst, setTypeOnFirst] = useState(true);
  const [getTab, setTabs] = useState('Position');
  const [orderTab, setOrderTab] = useState('market');
  const { positions, isLoading: positionLoading, refresh: refreshPosition, error: positionError } = usePosition(wallet.address || "", network)
  const { orders, isLoading: orderLoading, refresh: refreshOrder } = useOrder(wallet.address || "", network)
  const { histories, isLoading: historyLoading, refresh: refreshHistory } = useHistories(wallet.address || "", network)
  const allSymbols = Array.from(new Set(Object.keys(consts.abexCore.symbols).filter((item) => !OFFLINE_TOKEN.includes(item.replace('long', '').replace('short', '').toLowerCase())).map(e => parseSymbolKey(e)[1])))
  const priceChanges = useCryptoPriceChange(allSymbols)
  const [showPositionAdjust, setShowPositionAdjust] = useState(false)
  const [showPositionOpen, setShowPositionOpen] = useState(false)
  const [showPositionClose, setShowPositionClose] = useState(false)
  const [showSwapConfirm, setShowSwapConfirm] = useState(false)
  const [positionToAdjust, setPositionToAdjust] = useState<DisplayPosition | null>(null)
  const [orderToAdjust, setOrderToAdjust] = useState<IOrderInfo | null>(null)
  const [showOrderCancel, setShowOrderCancel] = useState(false)
  const [showShare, setShowShare] = useState(false)
  const symbol = joinSymbol(mode, indexToken)
  const { positionConfigMap } = usePositionConfig(network, consts)
  const [swapFromToken, setSwapFromToken] = useState<string>(DEFAULT_SWAP_FROM_TOKEN)
  const [swapToToken, setSwapToToken] = useState<string>(DEFAULT_SWAP_TO_TOKEN)
  const { fundingRateMap, isLoading: fundingRateLoading } = useFundingRate(indexToken, network)
  const { reservingRateMap, isLoading: reservingRateLoading, refresh: refreshReservingRate } = useReservingRate(collateralToken, network, (payAmount || 0) * leverage * (10 ** consts.coins[collateralToken].decimals))
  const { rebaseRate: swapFromRate } = useRebaseRate(swapFromToken, true, network, (payAmount || 0) * (10 ** consts.coins[swapFromToken].decimals))
  const swapToBeforeFee = (payAmount || 0) * prices[swapFromToken] / prices[swapToToken] * (1 - (swapFromRate || 0))
  const { rebaseRate: swapToRate } = useRebaseRate(swapToToken, false, network, swapToBeforeFee * (10 ** consts.coins[swapToToken].decimals))
  const swapToAmount = swapToBeforeFee * (1 - (swapToRate || 0))
  const swapFee = swapToAmount * ((swapFromRate || 0) + (swapToRate || 0))
  const { vaultInfoMap, isLoading: vaultInfoLoading, refresh: refreshVaultInfo } = useVaultInfo(network, consts)
  const [indexPrice, setIndexPrice] = useState<number | null>(null)
  const tokenIcons = useSvgIcon(Object.keys(consts.coins))
  const positionTableData = []
  const orderTableData = []
  const historyTableData = []
  const displayPositions = []
  const [hideNotice, setHideNotice] = useState(localStorage.getItem('hide-notice') ? true : false);
  const referralAddress = useQueryParam('referral')

  const stableCoins = ['usdt', 'usdc']

  const closeNotice = () => {
    setHideNotice(true);
    localStorage.setItem('hide-notice', 'true');
  }
  window.closedPositions = []
  window.executedOrders = []
  const getColor = (type: number) => {
    const data = {
      1: 'color-red-bg',
      2: 'color-red-bg',
      3: 'color-Fe-bg',
      4: 'color-Fe-bg',
      5: 'color-green-bg',
      6: 'color-green-bg'
    }
    return data[type as keyof typeof data]
  }
  const getProcessNum = (numPer:number) => {
    if (numPer <= 16) {
      return 1
    } else if (numPer <= 32) {
      return 2
    } else if (numPer <= 48) {
      return 3
    } else if (numPer <= 64) {
      return 4
    } else if (numPer <= 80) {
      return 5
    } else {
      return 6
    }
  }

  useEffect(() => {
    const timer = setInterval(() => {
      refreshHistory()
    }, 60_000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (!positions.length && !positionLoading && positionError) {
      enqueueSnackbar(positionError, { variant: 'error' });
    }
  }, [positions, positionLoading, positionError])

  for (let i = 0; i < positions.length; i++) {
    const position = positions[i]
    if (position.closed) {
      window.closedPositions.push(position)
      continue
    }
    const entryPrice = position.positionSize * (10 ** consts.coins[position.indexToken].decimals) / position.positionAmount
    const {
      pnlValue,
      pnlInCollateralPercentage,
      openFee,
    } = calculatePNL(position, prices, positionConfigMap, consts)
    const leverage = (position.positionAmount * prices[position.indexToken] / (10 ** consts.coins[position.indexToken].decimals) - pnlValue + openFee) / (position.collateralAmount * prices[position.collateralToken] / (10 ** consts.coins[position.collateralToken].decimals))

    const displayPosition: DisplayPosition = {
      ...position,
      pnl: pnlValue,
      leverage,
      entryPrice,
      liquidationPrice: entryPrice * (position.long ? (1 - 1 / leverage) : (1 + 1 / leverage)),
      pnlPercent: pnlInCollateralPercentage,
      collateralAmountDisplay: position.collateralAmount / (10 ** consts.coins[position.collateralToken].decimals),
      indexAmountDisplay: position.positionAmount / (10 ** consts.coins[position.indexToken].decimals),
    }

    const liqPrice = priceToString(
      calculateLiquidationPrice(
        displayPosition,
        positionConfigMap[
          joinSymbol(
            position.long ? 'long' : 'short',
            position.indexToken
          )
        ],
        prices[position.collateralToken],
        consts.coins[position.collateralToken].decimals
      )
    );
    const processPer = (1 + pnlInCollateralPercentage) * 100

    displayPositions.push(displayPosition)

    positionTableData.push([
      <div className='app-trade-table-symbol flex-col align-center' key={'position' + i}>
        <div className='app-trade-table-symbol-name'>{localSymbolToOriginal(position.indexToken)}/USD</div>
        <div className="flex justify-between w-full mt-4">
          <div className='flex items-center app-pair-dropdown_item-icon'>
            <img src={tokenIcons[position.indexToken]} alt='' className="app-trade-table-symbol-image z-10" />
            <img src={UsdcSvg} alt='' className="app-trade-table-symbol-image app-trade-table-symbol-image-left" />
          </div>
          <div className='app-trade-table-symbol-leverage ml-4' style={{ backgroundColor: position.long ? LONG_COLOR : SHORT_COLOR }}>
            {formatNumber(leverage, 1)}x
          </div>
        </div>
      </div>,
      <TokenAmount key={i} amount={position.positionSize} precision={2} symbol={'USD'} backgroundColor={i % 2 === 0 ? '#F6F6F6' : '#FFF'} />,
      <TokenAmount key={i} amount={position.collateralAmount / (10 ** consts.coins[position.collateralToken].decimals)} symbol={localSymbolToOriginal(position.collateralToken)} backgroundColor={i % 2 === 0 ? '#F6F6F6' : '#FFF'} />,
      priceToString(entryPrice),
      priceToString(prices[position.indexToken]),
      <div key={i} className='process-content' style={{backgroundColor: i % 2 === 0 ? '#F6F6F6' : '#FFF' }}>
        <div className='color-black-text-7 text-12 text-left'>
          {liqPrice}
        </div>
        <div className='flex justify-between items-center'>
          <div className="flex">
            {Array.from({length:6},(v,i)=>i+1).map((item) => {
              return (<span key={item} className={`process ${getProcessNum(processPer) >= item ? getColor(getProcessNum(processPer)) : ''} `}></span>)
            })}
          </div>
          <div>
            <img src={stableCoins.includes(position.indexToken.toLowerCase()) ? StableSvg : WaveSvg} alt="" />
          </div>
        </div>
      </div>,
      <PnL key={i} amount={pnlValue} percentage={pnlInCollateralPercentage * 100} />,
      <div key={i} className="table-btn adjust-btn" onClick={() => {
        setPositionToAdjust(displayPosition)
        setShowPositionAdjust(true)
      }} />,
      <div key={i} className="table-btn close-btn" onClick={() => {
        setPositionToAdjust(displayPosition)
        setShowPositionClose(true)
      }} />,
      <div key={i} className="table-btn share-btn" onClick={() => {
        setPositionToAdjust(displayPosition)
        setShowShare(true)
      }} />
    ])
  }
  for (let i = 0; i < orders.length; i++) {
    const order = orders[i]
    if (order.executed) {
      window.executedOrders.push(order)
      continue
    }

    let orderTypeDisplay = 'Unknown'
    let orderDescription = 'Unknown'
    let prefixSymbol = ''

    switch (order.orderType) {
      case 'OPEN_POSITION':
        orderTypeDisplay = 'Open Position'
        orderDescription = `Open ${order.long ? 'Long' : 'Short'} ${localSymbolToOriginal(order.indexToken)}/USD, Size: ${formatNumber(parseInt((order.openOrder?.openAmount || 0).toString()) * order.indexPrice / (10 ** consts.coins[order.indexToken].decimals))} USD, Collateral: ${formatNumber(parseInt((order.openOrder?.collateralAmount|| 0).toString()) / (10 ** consts.coins[order.collateralToken].decimals))} ${localSymbolToOriginal(order.collateralToken)}`
        prefixSymbol = order.long ? '<' : '>'
        break
      case 'DECREASE_POSITION':
        orderTypeDisplay = 'Decrease Position'
        orderDescription = `Decrase ${order.long ? 'Long' : 'Short'} ${localSymbolToOriginal(order.indexToken)}/USD, Size: ${formatNumber(parseInt((order.decreaseOrder?.decreaseAmount || 0).toString()) * order.indexPrice / (10 ** consts.coins[order.indexToken].decimals))} USD`
        prefixSymbol = (order.long && order.decreaseOrder?.takeProfit) || (!order.long && !order.decreaseOrder?.takeProfit) ? '>' : '<'
        break
      default:
        break
    }
    orderTableData.push([
      orderTypeDisplay,
      orderDescription,
      prefixSymbol + priceToString(order.indexPrice),
      priceToString(prices[order.indexToken]),
      <div key={i} className="table-btn reduce-btn" onClick={() => {
        setOrderToAdjust(order)
        setShowOrderCancel(true)
      }} />
    ])
  }
  for (let i = 0; i < histories.length; i++) {
    const history = histories[i]
    const createdAt = new Date(parseInt(history.timestamp))
    const formatter = new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric' });
    let actionName = 'Unknown'
    switch (history.attributes.eventName) {
      case 'OpenPositionSuccessEvent':
        actionName = 'Open'
        break
      case 'DecreasePositionSuccessEvent':
        actionName = 'Close'
        break
      case 'LiquidatePositionEvent':
        actionName = 'Liquidate'
        break
      default:
        break
    }
    historyTableData.push([
      <div className='app-trade-table-symbol flex-col align-center' key={'history' + i}>
        <div className='app-trade-table-symbol-name'>{createdAt.toLocaleDateString()}</div>
        <div className='rounded-2 app-trade-table-symbol-leverage mt-1 color-blue-bg'>
          {formatter.format(createdAt)}
        </div>
      </div>,
      <div className='app-trade-table-symbol flex-col align-center' key={'position' + i}>
        <div className='app-trade-table-symbol-name'>{localSymbolToOriginal(history.attributes.indexToken)}/USD</div>
        <div className="flex justify-between w-full mt-4">
          <div className='flex items-center app-pair-dropdown_item-icon'>
            <img src={tokenIcons[history.attributes.indexToken]} alt='' className="app-trade-table-symbol-image z-10" />
            <img src={UsdcSvg} alt='' className="app-trade-table-symbol-image app-trade-table-symbol-image-left" />
          </div>
          <div className='app-trade-table-symbol-leverage ml-4' style={{ backgroundColor: (history.attributes.direction === 'LONG' && actionName === 'Open') || (history.attributes.direction === 'SHORT' && actionName !== 'Open') ? LONG_COLOR : SHORT_COLOR }}>
            {actionName} {history.attributes.direction === 'LONG' ? 'Long' : 'Short'}
          </div>
        </div>
      </div>,
      `$${priceToString(history.attributes.indexPrice)}`,
      `$${formatNumber(history.attributes.volume)}`,
      `$${formatNumber(history.attributes.fee)}`,
      `$${formatNumber(history.attributes.pnl)}`,
      <div key={i} className="table-btn inter-network-btn" onClick={() => {
        window.open(`https://suivision.xyz/txblock/${history.transactionHash}`)
      }} />
    ])

  }

  const clearStates = () => {
    setPayAmount(null)
    setSecondAmount(null)
    setLeverage(DEFAULT_LEVERAGE)
    setSwapFromToken(DEFAULT_SWAP_FROM_TOKEN)
    setSwapToToken(DEFAULT_SWAP_TO_TOKEN)
    setTypeOnFirst(true)
    setIndexPrice(parseFloat(priceToString(prices[indexToken], false)))
  }

  let effectiveLeverage = leverage
  if (positionConfigMap[symbol]?.maxLeverage === leverage) {
    effectiveLeverage = leverage * 0.999
  }
  const realLeverage = getRealLeverage(effectiveLeverage, symbol, positionConfigMap)

  useEffect(() => {
    if (mode === 'swap') {
      if (typeOnFirst) {
        setSecondAmount(payAmount ? swapToAmount : null)
      } else {
        setPayAmount(secondAmount ? secondAmount * prices[swapToToken] / prices[swapFromToken] : null)
      }
    } else {
      const price = orderTab === 'market' ? prices[indexToken] : (indexPrice || prices[indexToken])
      if (typeOnFirst) {
        setSecondAmount(payAmount ? payAmount * prices[collateralToken] * realLeverage / price : null)
      } else {
        setPayAmount(secondAmount ? secondAmount * price / prices[collateralToken] / realLeverage : null)
      }
    }
  }, [payAmount, secondAmount, typeOnFirst, leverage, collateralToken, indexToken,
    prices, positionConfigMap, symbol, mode, swapFromToken, swapToToken, realLeverage, swapToAmount, indexPrice])

  useEffect(() => {
    if (!supportCollateralTokens.includes(collateralToken)) {
      setCollateralToken(supportCollateralTokens[0])
    }
    if (!indexTokenList.includes(indexToken)) {
      // TODO: Find another collateral token that supports this index token in the mode
    }
  }, [indexToken, collateralToken, indexTokenList, supportCollateralTokens])

  useEffect(() => {
    priceToString(prices[indexToken])
    const price = priceToString(prices[indexToken])
    if (price) {
      document.title = `${price} ${indexToken.toUpperCase()}/USD ABEx`;
    }
  }, [indexToken, prices])

  const priceNode = <div className='flex-row justify-start align-center app-trade-price-node'>
    <span className='detail-section-content' style={{ color: priceChanges.priceChanges[indexToken] > 0 ? LONG_COLOR : SHORT_COLOR }}>{`$${priceToString(prices[indexToken])}`}</span>
    <div style={{ background: priceChanges.priceChanges[indexToken] > 0 ? LONG_COLOR : SHORT_COLOR }}>{priceChanges.priceChanges[indexToken] > 0 ? '+' : ''}{formatNumber(priceChanges.priceChanges[indexToken])}%</div>
  </div>
  return (
    <div className={`${props.className} section-container mobile-margin flex flex-col`}>
      <div className="notice-card-mobile">
        <div className="sui-grant">
          <img src={SuiSvg} alt="Sui" />
          <span>Grant by Sui</span>
        </div>
        {
          !hideNotice && <div className="app-card-white">
            <div className="flex justify-between">
              <img src={NoticeSvg} alt="Notice" />
              <img src={MobileCloseSvg} alt="Close" onClick={closeNotice} />
            </div>
            <div className="notice-info">
              ABEx has launched the Referral module! With this feature, you can invite other users to trade. When the user makes a trade, you would earn rebates from their trading fees.
            </div>
          </div>
        }
      </div>
      <div className="app-card-white app-trade-overview-card flex-row justify-between align-center">
        <PairDropdown
          options={allSymbols.map(s => ({
            symbol0: TOKENS.find(e => e.symbol === s)?.symbolDisplay || '',
            symbol1: 'USD',
            indexToken: s,
            icon: TOKENS.find(e => e.symbol === s)?.icon || '',
          }))}
          onChange={(t: string) => {
            setIndexToken(t)
          }}
          selectedPair={{
            symbol0: TOKENS.find(e => e.symbol === indexToken)?.symbolDisplay || '',
            symbol1: 'USD',
            indexToken,
            icon: TOKENS.find(e => e.symbol === indexToken)?.icon || '',
          }}
          prices={prices}
          priceChanges={priceChanges.priceChanges}
        />
      <div className="flex justify-between ml-30 trade-number">
        <DetailSection className='app-trade-price' title='Price' node={!priceLoading ? priceNode : null} />
        <DetailSection className='funding'  title='Funding (L)' content={!fundingRateLoading ? `${formatNumberWithLeadingZeroInfo(fundingRateMap[joinSymbol('long', indexToken)] * 100)}%` : undefined} />
        <DetailSection className='funding' title='Funding (S)' content={!fundingRateLoading ? `${formatNumberWithLeadingZeroInfo(fundingRateMap[joinSymbol('short', indexToken)] * 100)}%` : undefined} />
      </div>
      <div className='flex-1 notice-card'>
        <img src={NoticeSvg} alt="Notice" />
        <div className="notice-info">ABEx has launched the Referral module! With this feature, you can invite other users to trade. When the user makes a trade, you would earn rebates from their trading fees.</div>
      </div>
      </div>
      <div className='flex-row justify-between align-start app-trade-main'>
        {/* <div className='flex-col'> */}
          <Chart symbol={indexToken} />
          <div className='rounded-16 color-white-bg'>
          <div className='ml-16 mr-16 flex-row justify-start app-trade-info-tab align-center'>
            <div onClick={() => {setTabs('Position')}} className={`ease-in color-F0-bg ${getTab === 'Position'&&'color-blue-bg color-white-text'}`}>
              Position({positionTableData.length})
            </div>
            <div onClick={() => {setTabs('Orders')}} className={`ease-in color-F0-bg ${getTab === 'Orders'&&'color-blue-bg color-white-text'}`}>
              Open Orders({orderTableData.length})
            </div>
            <div onClick={() => {setTabs('History')}} className={`ease-in color-F0-bg ${getTab === 'History'&&'color-blue-bg color-white-text'}`}>
              History
            </div>
          </div>
          {getTab === 'Position' && <>
            <Table titles={posistionTableTitles} rows={positionTableData}
              onChange={(page: number) => { console.log(page) }}
              className='app-position-table app-trade-table app-trade-position-table' loading={positionLoading} />
              <PositionTable loading={positionLoading} positions={positions} onAdjust={(positionData) => {
                setPositionToAdjust(positionData)
                setShowPositionAdjust(true)
              }} onCancel={(positionData) => {
                setPositionToAdjust(positionData)
                setShowPositionClose(true)
              }} onShare={(positionData) => {
                setPositionToAdjust(positionData)
                setShowShare(true)
              }}
              />
          </>}
          {getTab === 'Orders' && <Table titles={orderTableTitles} rows={orderTableData}
            onChange={(page: number) => { console.log(page) }}
            className='app-trade-table app-trade-orders-table' loading={orderLoading} />}
          {getTab === 'History' && <Table titles={historyTableTitles} rows={historyTableData}
            onChange={(page: number) => { console.log(page) }}
            className='app-trade-table app-trade-history-table' loading={historyLoading} />}
          </div>
          <TradeAction
            indexToken={indexToken}
            setIndexToken={setIndexToken}
            collateralTokenList={supportCollateralTokens}
            indexTokenList={indexTokenList}
            mode={mode}
            setMode={setMode}
            leverage={leverage}
            setLeverage={setLeverage}
            collateralToken={collateralToken}
            setCollateralToken={setCollateralToken}
            payAmount={payAmount}
            setPayAmount={setPayAmount}
            secondAmount={secondAmount}
            setSecondAmount={setSecondAmount}
            prices={prices}
            setTypeOnFirst={setTypeOnFirst}
            clearStates={clearStates}
            openPosition={() => setShowPositionOpen(true)}
            accountData={accountData}
            maxLeverage={positionConfigMap[symbol]?.maxLeverage}
            openFee={(leverage - realLeverage) / leverage * (payAmount || 0)}
            swapFromToken={swapFromToken}
            swapToToken={swapToToken}
            setSwapFromToken={setSwapFromToken}
            setSwapToToken={setSwapToToken}
            confirmSwap={() => setShowSwapConfirm(true)}
            swapFee={swapFee}
            reservingRate={reservingRateMap[collateralToken]}
            availableReserve={vaultInfoMap[collateralToken]?.liquidity}
            reservingRateLoading={reservingRateLoading}
            vaultInfoLoading={vaultInfoLoading}
            swapTokenList={Object.keys(consts.abexCore.vaults)}
            orderTab={orderTab}
            setOrderTab={setOrderTab}
            indexPrice={indexPrice}
            setIndexPrice={setIndexPrice}
            positionConfig={positionConfigMap[symbol]}
            consts={consts}
          />
          <PositionAdjust
            showPositionAdjust={showPositionAdjust}
            setShowPositionAdjust={setShowPositionAdjust}
            position={positionToAdjust}
            consts={consts}
            prices={prices}
            wallet={wallet}
            network={network}
            refreshAccount={refreshAccount}
            refreshPosition={refreshPosition}
            accountData={accountData}
            refreshVaultInfo={refreshVaultInfo}
            refreshReservingRate={refreshReservingRate}
          />
          <PositionOpen
            showPositionOpen={showPositionOpen}
            setShowPositionOpen={setShowPositionOpen}
            accountData={accountData}
            consts={consts}
            collateralToken={collateralToken}
            indexToken={indexToken}
            mode={mode}
            payAmount={payAmount || 0}
            secondAmount={secondAmount || 0}
            clearStates={clearStates}
            wallet={wallet}
            network={network}
            refreshAccount={refreshAccount}
            refreshPosition={refreshPosition}
            leverage={leverage}
            entryPrice={orderTab === 'limit' ? (indexPrice || 0) : prices[indexToken]}
            liquidationPrice={(orderTab === 'limit' ? (indexPrice || 0) : prices[indexToken]) * (mode === 'long' ? (1 - 1 / leverage) : (1 + 1 / leverage))}
            openFee={(leverage - realLeverage) / leverage * (payAmount || 0)}
            collateralPrice={prices[collateralToken]}
            refreshVaultInfo={refreshVaultInfo}
            refreshReservingRate={refreshReservingRate}
            reservingRate={reservingRateMap[collateralToken]}
            availableReserve={vaultInfoMap[collateralToken]?.liquidity}
            fundingRate={fundingRateMap[joinSymbol(mode, indexToken)]}
            orderTab={orderTab}
            refreshOrder={refreshOrder}
            positionConfig={positionConfigMap[symbol]}
            referralAddress={referralAddress}
          />
          <PositionClose
            position={positionToAdjust}
            showPositionClose={showPositionClose}
            setShowPositionClose={setShowPositionClose}
            wallet={wallet}
            network={network}
            refreshAccount={refreshAccount}
            refreshPosition={refreshPosition}
            consts={consts}
            markPrice={prices[positionToAdjust?.indexToken || '']}
            refreshVaultInfo={refreshVaultInfo}
            refreshReservingRate={refreshReservingRate}
            collateralToken={positionToAdjust?.collateralToken || ''}
            collateralPrice={prices[positionToAdjust?.collateralToken || '']}
            closeBps={positionConfigMap[joinSymbol(positionToAdjust?.long ? 'long' : 'short', positionToAdjust?.indexToken || "")]?.decreaseFeeBps}
            refreshOrder={refreshOrder}
          />
          <OrderCancel
            showOrderCancel={showOrderCancel}
            setShowOrderCancel={setShowOrderCancel}
            order={orderToAdjust}
            consts={consts}
            network={network}
            wallet={wallet}
            refreshAccount={refreshAccount}
            refreshOrder={refreshOrder}
            prices={prices}
          />
          <Share
            position={positionToAdjust}
            showShare={showShare}
            setShowShare={setShowShare}
            wallet={wallet}
          />
          <SwapConfirm
            showSwapConfirm={showSwapConfirm}
            setShowSwapConfirm={setShowSwapConfirm}
            accountData={accountData}
            consts={consts}
            swapFromToken={swapFromToken}
            swapToToken={swapToToken}
            payAmount={payAmount || 0}
            secondAmount={secondAmount || 0}
            clearStates={clearStates}
            wallet={wallet}
            network={network}
            refreshAccount={refreshAccount}
            swapToPrice={prices[swapToToken]}
            swapFee={swapFee}
          />
      </div>
    </div>
  );
};

export default Trade;
