import React from 'react';
import Modal from 'react-modal';
import "./follow.css";

import DiscordLogo from "../../assets/popup-discord-logo.svg";
import CenterLogo from "../../assets/popup-center-logo.svg";
import TwitterLogo from "../../assets/popup-twitter-logo.svg";

type Props = {
  showFollow: boolean;
  setShowFollow: Function;
};

const Follow: React.FC<Props> = ({ showFollow, setShowFollow }) => {
  return (
    <Modal className='follow-main flex-row align-center justify-center' overlayClassName='follow-main-overlay' isOpen={showFollow}>
      <div className='follow-card'>
        <div className='follow-card-logo-section flex-row justify-between'>
          <div className='flex-row justify-end'>
            <img src={DiscordLogo} alt='' />
          </div>
          <img src={CenterLogo} alt='' />
          <div className='flex-row justify-start'>
            <img src={TwitterLogo} alt='' />
          </div>
        </div>
        <div className='follow-card-desc'>
          <h3>Subscribe to find more</h3>
          <span>
            ABEx will be released soon, please subscribe our discord & twitter to find about new resources, commitments, proposals, and more.
          </span>
        </div>
        <div className='follow-card-button-group flex-row justify-between'>
          <a href='https://discord.gg/gTbkHAHU8B' target='_blank'  rel="noreferrer"> </a>
          <a href='https://twitter.com/ABExFinance' target='_blank'  rel="noreferrer"> </a>
          <div onClick={() => {
            setShowFollow(false)
          }} />
        </div>
      </div>
    </Modal>
  );
};

export default Follow;
