import React, { useState } from 'react';
import "./App.css"
import Follow from './components/follow';
import Footer from './components/footer';
import Header from './components/header';
import MainBlock from './components/main-block';
import SectionAB from './components/section-ab';
import SectionFeature from './components/section-feature';
import SectionIntro from './components/section-intro';
import SectionBC from './components/section-bc';
// import useResponsiveScale from '../hooks/useResponsiveScale';

function App() {
  const [showFollow, setShowFollow] = useState(false);
/*   useResponsiveScale({
    minWidth: 1200,
    maxWidth: 2100,
    minScale: 0.5,
    maxScale: 1,
  }) */
  return (
    <div className='global-container flex-col align-center justify-start'>
      <div className='background-image'>
        <div className='bg-fade-effect' />
      </div>
      <Header className='section-container header-section' setShowFollow={setShowFollow} />
      <MainBlock className='section-container main-block-section' setShowFollow={setShowFollow} />
      <SectionIntro className='section-container intro-section' />
      <SectionAB className='section-container ab-section' />
      <SectionFeature className='section-container feature-section' />
      <SectionBC className='section-container bc-section' />
      <div className='footer-divide-line'></div>
      <Footer className='section-container footer-section' />
      <Follow showFollow={showFollow} setShowFollow={setShowFollow} />
    </div>
  );
}

export default App;
