import Spline from '@splinetool/react-spline';
import React from 'react';
import "./main-block.css";

type Props = {
  className?: string
  setShowFollow: Function;
};

const MainBlock: React.FC<Props> = (props) => {
  return (
    <div className={props.className}>
      <div className='main-block-card card-max-black flex-col'>
        <h1>Seek<br />Alpha & Beta</h1>
        <span>ABEx is a revonluationary on-chain derivatives protocol within the Sui ecosystem.</span>
        <a href='/app/trade'><div className='launch-icon align-center justify-center' style={{width: 200, height: 60}}>Launch ABEx</div></a>
        <div className='main-block-shape'>
        <Spline scene='https://prod.spline.design/sTvagmG2LrveCTw9/scene.splinecode'></Spline>
        </div>
      </div>
    </div>
  );
};

export default MainBlock;