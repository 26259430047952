import { IConsts, IPositionConfig, IPositionInfo, joinSymbol } from "abex-ts-sdk";
import { DisplayPosition } from "./models";

export function getRealLeverage(leverage: number, symbol: string, positionConfigMap: { [key: string]: IPositionConfig }) {
  return leverage / (1 + positionConfigMap[symbol]?.openFeeBps * leverage)
}

export function getSwapAmountAndFee(
  rebaseRateMap: { [key: string]: number },
  swapFromToken: string,
  swapToToken: string,
  prices: { [key: string]: number },
  swapFromAmount: number,
) {
  const swapToAmount = swapFromAmount * (1 - rebaseRateMap[[swapFromToken, true].join()]) * prices[swapFromToken] / prices[swapToToken]
  const fee = swapToAmount * rebaseRateMap[[swapToToken, false].join()]
  return {
    swapToAmount: swapToAmount - fee,
    fee,
  }
}

export function calculatePNL(
  position: IPositionInfo,
  prices: { [key: string]: number },
  positionConfigMap: { [key: string]: IPositionConfig },
  consts: IConsts,
) {
  // Size = indexTokenPrice * indexTokenAmount
  // OpenFee = EntrySize * openFeeBps
  // ReservingFee = accReservingRate ???
  // FundingFee = accFundingRate ???
  // $PNL = Size - EntrySize - OpenFee - ReservingFee - FundingFee
  // PNL% ???
  const size = prices[position.indexToken] * position.positionAmount / (10 ** consts.coins[position.indexToken].decimals)
  const openFee = position.positionSize * positionConfigMap[joinSymbol(position.long ? 'long' : 'short', position.indexToken)]?.openFeeBps
  const reservingFee = position.reservingFeeAmount * prices[position.collateralToken] / (10 ** consts.coins[position.collateralToken].decimals)
  const fundingFee = position.fundingFeeValue
  const delta = (size - position.positionSize) * (position.long ? 1 : -1)
  const pnlValue = delta - openFee - reservingFee - fundingFee
  const pnlInCollateral = pnlValue / prices[position.collateralToken]
  const pnlInCollateralPercentage = pnlInCollateral / position.collateralAmount * (10 ** consts.coins[position.collateralToken].decimals)
  return {
    delta,
    reservingFee,
    fundingFee,
    pnlValue,
    pnlInCollateral,
    pnlInCollateralPercentage,
    openFee,
  }
}

export function calculateLiquidationPrice(position: DisplayPosition, config: IPositionConfig, collateralPrice: number, collateralDecimals: number): number {
  if (!position) return 0
  if (!config) return 0
  const reservingFee = position.reservingFeeAmount * collateralPrice / (10 ** collateralDecimals)
  const fundingFee = position.fundingFeeValue
  const C = position.collateralAmountDisplay * collateralPrice - reservingFee - fundingFee; // Collateral value
  const P = position.positionSize;           // Position size
  const E = position.entryPrice;             // Entry price
  const L = config.liquidationThreshold;     // Liquidation threshold

  if (position.long) {
    // For long positions
    return E - (E * L * C / P);
  } else {
    // For short positions
    return E + (E * L * C / P);
  }
}
