import { API, DataAPI, IMarketValuationInfo } from "abex-ts-sdk";
import { useState, useEffect } from "react";
import { getProvider } from '../app/utils';

export function useValuateMarket(network: string) {
  const [marketInfo, setMarketInfo] = useState<IMarketValuationInfo>({
    alpSupply: 0,
    alpPrice: 0,
    marketCap: 0,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<number>(0);

  useEffect(() => {
    if (isLoading) return;
    setIsLoading(true);
    const dataAPI = API.getInstance(network, getProvider(network));
    dataAPI.valuateMarket().then((info: IMarketValuationInfo) => {
      setMarketInfo(info);
    }).catch((err) => {
      setError(err.message);
    }).finally(() => {
      setIsLoading(false);
    })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [network, refreshToken]);

  const refresh = () => {
    setRefreshToken(prevToken => prevToken + 1);
  };

  return {
    marketInfo,
    isLoading,
    error,
    refresh,
  }
}
