import React, { useState } from 'react';
import Modal from 'react-modal';
import './PositionOpen.css'
import { executeApiCall, formatNumber, formatNumberWithLeadingZeroInfo, getCoins, localSymbolToOriginal, priceToString } from '../utils';
import { IAccountData } from '../../hooks/useAccountData';
import { IConsts, IPositionConfig } from 'abex-ts-sdk';
import { WalletContextState } from '@suiet/wallet-kit';
import Button from './Button';
import ModalHeader from './ModalHeader';
import ConfirmLong from '../../assets/confirm-long.svg';
import ConfirmShort from '../../assets/confirm-short.svg';
import InfoList from './InfoList';
import { RELAYER_FEE } from '../constants';
import { useQueryParam } from '../../hooks/useQueryParam';


type Props = {
  showPositionOpen: boolean;
  setShowPositionOpen: (show: boolean) => void;
  accountData: IAccountData;
  consts: IConsts;
  collateralToken: string;
  indexToken: string;
  mode: 'long' | 'short' | 'swap';
  payAmount: number;
  secondAmount: number;
  clearStates: () => void;
  wallet: WalletContextState;
  network: string;
  refreshAccount: () => void;
  refreshPosition: () => void;
  leverage: number;
  entryPrice: number;
  liquidationPrice: number;
  collateralPrice: number;
  openFee?: number;
  refreshVaultInfo: () => void;
  refreshReservingRate: () => void;
  reservingRate: number;
  availableReserve: number;
  fundingRate: number;
  orderTab: string;
  refreshOrder: () => void;
  positionConfig: IPositionConfig;
  referralAddress?: string;
}

const PositionOpen: React.FC<Props> = ({
  showPositionOpen,
  setShowPositionOpen,
  accountData,
  consts,
  collateralToken,
  indexToken,
  mode,
  payAmount,
  secondAmount,
  clearStates,
  wallet,
  network,
  refreshAccount,
  refreshPosition,
  leverage,
  entryPrice,
  liquidationPrice,
  collateralPrice,
  refreshVaultInfo,
  refreshReservingRate,
  reservingRate,
  availableReserve,
  fundingRate,
  openFee=0,
  orderTab,
  refreshOrder,
  positionConfig,
  referralAddress,
}) => {
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const usedReserve = (payAmount || 0 - openFee) * Math.min((leverage || 0), (positionConfig?.maxReservedMultiplier || 0))
  const openPosition = () => {
    setIsSubmitLoading(true)
    executeApiCall(
      async (api) => {
        const coins = await getCoins(accountData.address, consts.coins[collateralToken].module, network);
        const coinObjects = coins.map((e) => e.objectId);
        return api.openPosition(
          collateralToken,
          indexToken,
          BigInt((secondAmount * (10 ** consts.coins[indexToken].decimals)).toFixed(0)),
          BigInt((payAmount * (10 ** consts.coins[collateralToken].decimals)).toFixed(0)),
          coinObjects,
          mode === 'long',
          BigInt((payAmount * Math.min(leverage, (positionConfig?.maxReservedMultiplier || 0)) * (10 ** consts.coins[collateralToken].decimals)).toFixed(0)),
          entryPrice,
          collateralPrice,
          0.003,  // FIXME: hardcode
          orderTab === 'limit',
          false,  // FIXME: hardcode
          orderTab === 'limit'? BigInt(RELAYER_FEE * 1e9): BigInt(1),
          referralAddress,
          wallet.address,
        );
      },
      (res) => {
        clearStates();
        return `Success send tx: ${res?.digest}`;
      },
      (e) => `Error: ${e.message}`,
      wallet,
      network,
      [refreshAccount, refreshPosition, refreshVaultInfo, refreshReservingRate, refreshOrder],
      [() => setShowPositionOpen(false)],
    ).finally(() => setIsSubmitLoading(false));
  }

  const listContent : [string, string|number|JSX.Element, string?][] = [
    ['Leverage', `${leverage}x`],
    ['Allowed Slippage', '0.30%'],
    [' ', ' '],
    ['Avail. Reserve', `${formatNumber(usedReserve)}/${formatNumber(availableReserve / (10 ** consts.coins[collateralToken].decimals))} ${localSymbolToOriginal(collateralToken)}`, 'The maximum profit user will receive for the position / Total reserve can be used for opening new positions.'],
    ['Reserving Rate', `${formatNumberWithLeadingZeroInfo(reservingRate * 100)}%`, 'Traders pay interest to LPs based on the reserve of the position in 8 hours.'],
    ['Funding Rate',  `${formatNumberWithLeadingZeroInfo(fundingRate * 100)}%`, 'Based on dynamic funding rate, calculated for last 8 hours.'],
    [' ', ' '],
    ['Entry Price', priceToString(entryPrice)],
    ['Liq. Price', priceToString(liquidationPrice)],
    [' ', ' '],
    ['Collateral', `${formatNumber(payAmount, 4)} ${localSymbolToOriginal(collateralToken)}`],
    ['Open Fee', `${formatNumber(openFee)} ${localSymbolToOriginal(collateralToken)} ($${formatNumber(collateralPrice * openFee)})`],
  ]

  if (orderTab === 'limit') {
    listContent.push(['Relayer Fee', `${RELAYER_FEE} SUI`])
  }

  return (
    <Modal
      className='app-pos-close-main'
      isOpen={showPositionOpen}
      onRequestClose={() => {
        setShowPositionOpen(false)
      }}
      shouldCloseOnOverlayClick={true}
    >
      <div className='app-pos-open-card'>
        <ModalHeader
          icon={mode === 'long' ? ConfirmLong : ConfirmShort}
          text={`${localSymbolToOriginal(indexToken)}/USD ${mode === 'long' ? 'Long' : 'Short'}`}
          setShowModal={() => setShowPositionOpen(false)}
        />
        <div className='app-pos-open-token-info flex-row justify-between'>
          <div className='app-pos-open-token-info-item flex-col'>
            <div className='app-pos-open-token-info-item-title flex-row justify-between'>
              <div>Pay</div>
              <div>{localSymbolToOriginal(collateralToken)}</div>
            </div>
            <div className='app-pos-open-token-info-item-value'>
              {formatNumber(payAmount, 4)}
            </div>
          </div>
          <div className='app-pos-open-token-info-item flex-col'>
            <div className='app-pos-open-token-info-item-title flex-row justify-between'>
              <div>{mode === 'long' ? 'Long' : 'Short'}</div>
              <div>{localSymbolToOriginal(indexToken)}</div>
            </div>
            <div className='app-pos-open-token-info-item-value'>
              {formatNumber(secondAmount, 4)}
            </div>
          </div>
        </div>
        <InfoList
          content={listContent}
          className='app-pos-open-info-list'
        />
        <div className='app-pos-open-warning'>
          <div className='app-pos-open-warning-title'>Warning</div>
          <div className='app-pos-open-warning-content'>
          Before you confirm the order, please make sure you have read our risk warning information. If you click to confirm the order, it means that you have read and agreed to our <a href='https://abex-protocol.gitbook.io/abex/' target='_blank' rel='noreferrer'>relevant terms</a>
          </div>
        </div>
        <Button
          buttonStyle={mode === 'long' ? 'long' : 'short'}
          text={orderTab === 'market'? `Confirm ${mode === 'long' ? 'Long' : 'Short'}`: 'Place Order'}
          onClick={openPosition}
          isLoading={isSubmitLoading}
        />
      </div>
    </Modal>
  )
}

export default PositionOpen