import { WalletContextState } from '@suiet/wallet-kit';
import { QRCodeCanvas } from 'qrcode.react';
import { useCallback, useRef } from "react";
import { toPng } from 'html-to-image';
import Modal from 'react-modal';

import { DisplayPosition } from '../models';
import ModalHeader from './ModalHeader';
import ShareTitle from '../../assets/share-title.svg';
import ShareLongShort from '../../assets/share-long-short.svg';
import Logo from '../../assets/share-logo.svg';
import './Share.css';
import Button from './Button';
import { formatNumber, localSymbolToOriginal, priceToString } from '../utils';
import { useEssential } from '../App';

type Props = {
  position: DisplayPosition | null;
  showShare: boolean;
  setShowShare: (show: boolean) => void;
  wallet: WalletContextState;
}

const Share = ({ position, showShare, setShowShare, wallet }: Props) => {
  const { prices } = useEssential();
  const ref = useRef<HTMLDivElement>(null);

  const download = useCallback(() => {
    if (ref.current === null) {
      return
    }

    toPng(ref.current, { quality: 1, })
      .then((dataUrl) => {
        const link = document.createElement('a')
        link.download = `${localSymbolToOriginal(position?.indexToken ?? '')}_USD.png`
        link.href = dataUrl
        link.click()
      })
      .catch((err) => {
        console.log(err)
      })
  }, [ref, position])

  return (
    <Modal
      className="share-modal modal-animation"
      isOpen={showShare}
      onRequestClose={() => {
        setShowShare(false)
      }}
      shouldCloseOnOverlayClick={true}
    >
      <ModalHeader
        className="share-modal-header"
        icon={ShareTitle}
        text="Share Your Position"
        setShowModal={() => setShowShare(false)}
      />
      <div className="share-modal-body">
        <div className="share-modal-container">
          <div ref={ref} className="share-info-wrap">
            <div className="share-info-container flex justify-between">
              <div className="flex flex-col">
                <div>
                  <span className={`share-text ${position?.long ? 'long' : 'short'}`}>{position?.long ? 'LONG' : 'SHORT'}</span>
                  <span className="share-text ml-8">{`${formatNumber(position?.leverage ?? 0, 2)}x`}</span>
                  <span className="share-text ml-8">{localSymbolToOriginal(position?.indexToken ?? '')}/USD</span>
                </div>
                <div className={`share-increase ${position?.pnlPercent && position?.pnlPercent < 0 ? 'share-short' : 'share-long'}`}>
                  {position?.pnlPercent && position?.pnlPercent < 0 ? "-": "+"}{formatNumber(Math.abs((position?.pnlPercent ?? 0) * 100), 2)}%
                </div>
              </div>
              <div className="share-long-short">
                <img src={ShareLongShort} alt="" />
              </div>
            </div>
            <div className="share-price flex items-center">
              <div className="flex flex-col ml-24 flex-nowrap min-w-100">
                <span className="share-price-title">ENTRY PRICE</span>
                <span className="share-price-info">{priceToString(position?.entryPrice ?? 0)}</span>
              </div>
              <div className="flex flex-col flex-nowrap ml-24 min-w-100">
                <span className="share-price-title">MARKET PRICE</span>
                <span className="share-price-info">{priceToString(prices[position?.indexToken ?? 0])}</span>
              </div>
            </div>
            <div className="qr-code-wrap flex items-center">
              <div>
                <QRCodeCanvas size={56} value={`https://abex.fi/app/trade?referral=${position?.owner}`} />
              </div>
              <div className="flex flex-col ml-24 flex-nowrap min-w-100">
                <span className="share-price-title">Experience ABEx</span>
                <span className="share-price-info">abex.fi</span>
              </div>
            </div>
            <div className="share-bottom">
              <img src={Logo} alt="Logo" />
            </div>
          </div>
        </div>
      </div>
      <div className="share-modal-footer">
        <Button buttonStyle="main" text="Save" isLoading={false} onClick={() => download()} />
      </div>
    </Modal>
  )
}

export default Share;
