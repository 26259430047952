import { defineWallet } from "@suiet/wallet-kit";

export const TOKENS = [
  {
    name: 'Bitcoin',
    symbol: 'btc',
    icon: require('../assets/tokens/btc.svg').default,
    symbolDisplay: 'BTC',
  },
  {
    name: 'Ethereum',
    symbol: 'eth',
    icon: require('../assets/tokens/eth.svg').default,
    symbolDisplay: 'ETH',
  },
  {
    name: 'Binance Coin',
    symbol: 'bnb',
    icon: require('../assets/tokens/bnb.svg').default,
    symbolDisplay: 'BNB',
  },
  {
    name: 'Cardano',
    symbol: 'ada',
    icon: require('../assets/tokens/ada.svg').default,
    symbolDisplay: 'ADA',
  },
  {
    name: 'Solana',
    symbol: 'sol',
    icon: require('../assets/tokens/sol.svg').default,
    symbolDisplay: 'SOL',
  },
  {
    name: 'Litecoin',
    symbol: 'ltc',
    icon: require('../assets/tokens/ltc.svg').default,
    symbolDisplay: 'LTC',
  },
  {
    name: 'Polkadot',
    symbol: 'dot',
    icon: require('../assets/tokens/dot.svg').default,
    symbolDisplay: 'DOT',
  },
  {
    name: 'Avalanche',
    symbol: 'avax',
    icon: require('../assets/tokens/avax.svg').default,
    symbolDisplay: 'AVAX',
  },
  {
    name: 'Sui',
    symbol: 'sui',
    icon: require('../assets/tokens/sui.svg').default,
    symbolDisplay: 'SUI',
  },
  {
    name: 'Tether',
    symbol: 'usdt',
    icon: require('../assets/tokens/usdt.svg').default,
    symbolDisplay: 'USDT',
  },
  {
    name: 'ABEx Liquidity Token',
    symbol: 'alp',
    icon: require('../assets/tokens/alp.svg').default,
    symbolDisplay: 'ALP',
  },
  {
    name: 'fake Sui',
    symbol: 'fsui',
    icon: require('../assets/tokens/sui.svg').default,
    symbolDisplay: 'FSUI',
  },
  {
    name: 'Doge Coin',
    symbol: 'doge',
    icon: require('../assets/tokens/doge.svg').default,
    symbolDisplay: 'DOGE',
  },
  {
    name: 'Pepe',
    symbol: 'pepe',
    icon: require('../assets/tokens/pepe.svg').default,
    symbolDisplay: 'PEPE',
  },
  {
    name: 'USD',
    symbol: 'usd',
    icon: require('../assets/tokens/usd.svg').default,
    symbolDisplay: 'USD',
  },
  {
    name: 'USDC',
    symbol: 'usdc',
    icon: require('../assets/tokens/usdc.svg').default,
    symbolDisplay: 'USDC',
  },
  {
    name: "CETUS",
    symbol: "cetus",
    icon: require('../assets/tokens/cetus.svg').default,
    symbolDisplay: "CETUS",
  },
  {
    name: 'Worldcoin',
    symbol: 'wld',
    icon: require('../assets/tokens/wld.svg').default,
    symbolDisplay: 'WLD',
  },
  {
    name: 'Blur',
    symbol: 'blur',
    icon: require('../assets/tokens/blur.svg').default,
    symbolDisplay: 'BLUR',
  },
];

export const OFFLINE_TOKEN = ['cetus'];

export const MAIN_COLOR = '#007AF0'

export const LONG_COLOR = '#0ECA82'
export const SHORT_COLOR = '#FA3C58'
export const SWAP_COLOR = '#007AF0'

export const LONG_LEVERAGE_MIN = 1
export const LONG_LEVERAGE_MAX = 20
export const LONG_LEVERAGE_STEP = 0.5

export const SHORT_LEVERAGE_MIN = 1
export const SHORT_LEVERAGE_MAX = 20
export const SHORT_LEVERAGE_STEP = 0.5

export const DEFAULT_INDEX_TOKEN = 'btc'
export const DEFAULT_COLLATERAL_TOKEN = 'usdc'
export const DEFAULT_SWAP_FROM_TOKEN = 'sui'
export const DEFAULT_SWAP_TO_TOKEN = 'usdc'
export const DEFAULT_LEVERAGE = 10

export const ACCOUNT_REFRESH_INTERVAL = 5000
export const PRICE_REFRESH_INTERVAL = 5000

export const POOL_OP_MIN_FEE = 1
export const SWAP_OP_MIN_FEE = 1
export const PERP_OP_MIN_FEE = 0.2
export const RELAYER_FEE = 0.1

export const okxWallet = defineWallet({
  name: 'OKX Wallet',
  iconUrl: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwMCIgaGVpZ2h0PSIxMDAwIiB2aWV3Qm94PSIwIDAgMTAwMCAxMDAwIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cmVjdCB3aWR0aD0iMTAwMCIgaGVpZ2h0PSIxMDAwIiBmaWxsPSJibGFjayIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTM5My45NDkgMjE4LjUxOEgyMzEuMDQ5QzIyNC4xMjkgMjE4LjUxOCAyMTguNTE5IDIyNC4xMjggMjE4LjUxOSAyMzEuMDQ4VjM5My45NDhDMjE4LjUxOSA0MDAuODY5IDIyNC4xMjkgNDA2LjQ3OSAyMzEuMDQ5IDQwNi40NzlIMzkzLjk0OUM0MDAuODcgNDA2LjQ3OSA0MDYuNDggNDAwLjg2OSA0MDYuNDggMzkzLjk0OFYyMzEuMDQ4QzQwNi40OCAyMjQuMTI4IDQwMC44NyAyMTguNTE4IDM5My45NDkgMjE4LjUxOFpNNTgxLjk5MiA0MDYuNDc5SDQxOS4wOTJDNDEyLjE3MiA0MDYuNDc5IDQwNi41NjEgNDEyLjA5IDQwNi41NjEgNDE5LjAxVjU4MS45MUM0MDYuNTYxIDU4OC44MzEgNDEyLjE3MiA1OTQuNDQxIDQxOS4wOTIgNTk0LjQ0MUg1ODEuOTkyQzU4OC45MTMgNTk0LjQ0MSA1OTQuNTIzIDU4OC44MzEgNTk0LjUyMyA1ODEuOTFWNDE5LjAxQzU5NC41MjMgNDEyLjA5IDU4OC45MTMgNDA2LjQ3OSA1ODEuOTkyIDQwNi40NzlaTTYwNi45NzQgMjE4LjUxOEg3NjkuODc0Qzc3Ni43OTQgMjE4LjUxOCA3ODIuNDA1IDIyNC4xMjggNzgyLjQwNSAyMzEuMDQ4VjM5My45NDhDNzgyLjQwNSA0MDAuODY5IDc3Ni43OTQgNDA2LjQ3OSA3NjkuODc0IDQwNi40NzlINjA2Ljk3NEM2MDAuMDUzIDQwNi40NzkgNTk0LjQ0MyA0MDAuODY5IDU5NC40NDMgMzkzLjk0OFYyMzEuMDQ4QzU5NC40NDMgMjI0LjEyOCA2MDAuMDUzIDIxOC41MTggNjA2Ljk3NCAyMTguNTE4Wk0zOTMuOTUgNTk0LjQ0MkgyMzEuMDQ5QzIyNC4xMjkgNTk0LjQ0MiAyMTguNTE5IDYwMC4wNTIgMjE4LjUxOSA2MDYuOTczVjc2OS44NzNDMjE4LjUxOSA3NzYuNzkzIDIyNC4xMjkgNzgyLjQwNCAyMzEuMDQ5IDc4Mi40MDRIMzkzLjk1QzQwMC44NyA3ODIuNDA0IDQwNi40OCA3NzYuNzkzIDQwNi40OCA3NjkuODczVjYwNi45NzNDNDA2LjQ4IDYwMC4wNTIgNDAwLjg3IDU5NC40NDIgMzkzLjk1IDU5NC40NDJaTTYwNi45NzQgNTk0LjQ0Mkg3NjkuODc0Qzc3Ni43OTQgNTk0LjQ0MiA3ODIuNDA1IDYwMC4wNTIgNzgyLjQwNSA2MDYuOTczVjc2OS44NzNDNzgyLjQwNSA3NzYuNzkzIDc3Ni43OTQgNzgyLjQwNCA3NjkuODc0IDc4Mi40MDRINjA2Ljk3NEM2MDAuMDUzIDc4Mi40MDQgNTk0LjQ0MyA3NzYuNzkzIDU5NC40NDMgNzY5Ljg3M1Y2MDYuOTczQzU5NC40NDMgNjAwLjA1MiA2MDAuMDUzIDU5NC40NDIgNjA2Ljk3NCA1OTQuNDQyWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==',
  downloadUrl: {
    browserExtension: 'https://chrome.google.com/webstore/detail/okx-wallet/mcohilncbfahbmgdjkbpemcciiolgcge',
  },
  label: 'OKX Wallet',
})