import { API, DataAPI } from "abex-ts-sdk"
import { useEffect, useRef, useState } from "react"
import { getProvider } from "../app/utils"
import { SUI_CLOCK_OBJECT_ID } from "@mysten/sui.js";

export function useRebaseRate(collateralToken: string, increase: boolean, network: string, amount: number) {
  const [rebaseRate, setRebaseRate] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [amount_, setAmount] = useState<number>(0);
  const currentParamsRef = useRef({ collateralToken, increase, network, amount });
  const needRefresh = Math.abs((amount - amount_) / amount) > 0.1

  useEffect(() => {
    if (!collateralToken) return;
    if (!needRefresh) return;

    setIsLoading(true);
    const dataAPI = API.getInstance(network, getProvider(network));
  
    // Update the current parameters ref on every effect execution
    currentParamsRef.current = { collateralToken, increase, network, amount };

    dataAPI.rebaseFeeRate(collateralToken, increase, amount)
      .then((rebaseRateValue) => {
        // Only update state if the parameters are still the same
        if (currentParamsRef.current.collateralToken === collateralToken &&
            currentParamsRef.current.increase === increase &&
            currentParamsRef.current.network === network &&
            currentParamsRef.current.amount === amount) {
          setRebaseRate(rebaseRateValue);
          setAmount(amount);
        }
      })
      .catch((err) => {
        setError(err.message);
      }).finally(() => {
        setIsLoading(false);
      });
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collateralToken, increase, network, amount]);

  return {
    rebaseRate,
    isLoading,
    error,
  }
}