import React, { useRef } from 'react';
import useTriggerClassRef from '../../hooks/useTriggerClassRef';
import "./section-feature.css";

import Feature1 from '../../assets/feature-1.svg';
import Feature2 from '../../assets/feature-2.svg';
import Feature3 from '../../assets/feature-3.svg';
import Feature4 from '../../assets/feature-4.svg';


type Props = {
  className?: string;
};

const SectionFeature: React.FC<Props> = (props) => {
  const elementToAnimateTitle = useTriggerClassRef('title-animate-fade-in')
  const elementToAnimate2 = useRef(null)
  const elementToAnimate3 = useRef(null)
  const elementToAnimate4 = useRef(null)
  const elementToAnimate = useTriggerClassRef('feature-animate-fade-in', [
    ['feature-animate-fade-in-2', elementToAnimate2],
    ['feature-animate-fade-in-3', elementToAnimate3],
    ['feature-animate-fade-in-4', elementToAnimate4],

  ])
  return (
    <div className={props.className}>
      <h2 className='section-title' ref={elementToAnimateTitle}>
        ABEx strive to improve your trading experience
      </h2>
      <div className='feature-content'>
        <div className='card-max-black'>
          <h3>Zero-Slippage Trading</h3>
          <span>
            ABEx leverage on the liquidity provided by LP and oracle feed price so traders can trade with zero price impact
          </span>
          <img src={Feature4} alt='' ref={elementToAnimate} />
        </div>
        <div className='card-max-black'>
          <h3>Synthetic Architecture</h3>
          <span>
            Traders can use any index asset as collateral and open long and short positions on crypto and any other assets (Stock price, commodities, etc)
          </span>
          <img src={Feature3} alt='' ref={elementToAnimate2} />
        </div>
        <div className='card-max-black'>
          <h3>High Capital Efficiency</h3>
          <span>
            ALP holders can borrow against their positions and use borrowed assets at external protocols for yield strategy
          </span>
          <img src={Feature2} alt='' ref={elementToAnimate3} />
        </div>
        <div className='card-max-black'>
          <h3>Dynamic Funding Rate</h3>
          <span>
            ABEx will adjust the funding rate at a single-sided market and the dynamic funding rate will balance the overall risks for the protocol
          </span>
          <img src={Feature1} alt='' ref={elementToAnimate4} />
        </div>
      </div>
    </div>
  );
};

export default SectionFeature;