import { API, DataAPI, IConsts, IPositionConfig, parseSymbolKey } from "abex-ts-sdk"
import { useEffect, useState } from "react"
import { getProvider } from "../app/utils"

export function usePositionConfig(network: string, consts: IConsts) {
  const [positionConfigMap, setPositionConfigMap] = useState<{[key: string]: IPositionConfig}>({})
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (isLoading) return
    setIsLoading(true);
    const dataAPI = API.getInstance(network, getProvider(network))
    Promise.all(Object.keys(consts.abexCore.symbols).map(async (symbol) => {
      const [direction, indexToken] = parseSymbolKey(symbol)
      const config = await dataAPI.getPositionConfig(indexToken, direction === 'long')
      setPositionConfigMap(prevMap => ({
        ...prevMap,
        [symbol]: config,
      }))
    })).catch((err) => {
      setError(err.message)
    }).finally(() => {
      setIsLoading(false)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [network]);

  return {
    positionConfigMap,
    isLoading,
    error,
  }
}
