import { IPositionInfo, joinSymbol } from 'abex-ts-sdk'
import { LONG_COLOR, SHORT_COLOR } from '../../constants'
import { calculateLiquidationPrice, calculatePNL } from '../../math'
import { DisplayPosition } from '../../models'
import { localSymbolToOriginal, formatNumber, priceToString } from '../../utils'
import { useEssential } from '../../App'
import { usePositionConfig } from '../../../hooks/usePositionConfig'
import useSvgIcon from '../../../hooks/useSvgIcon'
import UsdcSvg from '../../../assets/tokens/usdc.svg'
import StableSvg from '../../../assets/stable.svg'
import WaveSvg from '../../../assets/wave.svg'
import Skeleton from 'react-loading-skeleton'

interface TableProps {
  positions: IPositionInfo[]
  loading: boolean
  onAdjust: (displayPosition: DisplayPosition) => void
  onShare: (displayPosition: DisplayPosition) => void
  onCancel: (displayPosition: DisplayPosition) => void
}

const PositionTable: React.FC<TableProps> = ({
  loading,
  positions,
  onAdjust,
  onShare,
  onCancel,
}) => {
  const { network, consts, prices } = useEssential()
  const { positionConfigMap } = usePositionConfig(network, consts)
  const tokenIcons = useSvgIcon(Object.keys(consts.coins))

  const stableCoins = ['usdt', 'usdc']

  if (loading) {
    return (
      <div className="app-position-table-mobile">
        <div className="position-item">
          <div className="flex justify-between">
            <Skeleton count={1} width={80} />
            <Skeleton count={1} width={80} />
            <Skeleton count={1} width={80} />
          </div>
          <div className="flex justify-between mt-8">
            <Skeleton count={1} width={80} />
            <Skeleton count={1} width={80} />
            <Skeleton count={1} width={80} />
          </div>
          <div className="flex justify-between mt-8">
            <Skeleton count={1} width={80} />
            <Skeleton count={1} width={80} />
            <Skeleton count={1} width={80} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="app-position-table-mobile">
      {positions
        .filter((item) => {
          if (item.closed) {
            return false
          }
          return true
        })
        .map((position) => {
          const isStable = stableCoins.includes(
            position.indexToken.toLowerCase()
          )
          const entryPrice =
            (position.positionSize *
              10 ** consts.coins[position.indexToken].decimals) /
            position.positionAmount
          const { pnlValue, pnlInCollateralPercentage, openFee } = calculatePNL(
            position,
            prices,
            positionConfigMap,
            consts
          )
          const leverage =
            ((position.positionAmount * prices[position.indexToken]) /
              10 ** consts.coins[position.indexToken].decimals -
              pnlValue +
              openFee) /
            ((position.collateralAmount * prices[position.collateralToken]) /
              10 ** consts.coins[position.collateralToken].decimals)

          const displayPosition: DisplayPosition = {
            ...position,
            pnl: pnlValue,
            leverage,
            entryPrice,
            liquidationPrice:
              entryPrice *
              (position.long ? 1 - 1 / leverage : 1 + 1 / leverage),
            pnlPercent: pnlInCollateralPercentage,
            collateralAmountDisplay:
              position.collateralAmount /
              10 ** consts.coins[position.collateralToken].decimals,
            indexAmountDisplay:
              position.positionAmount /
              10 ** consts.coins[position.indexToken].decimals,
          }

          const liqPrice = formatNumber(
            calculateLiquidationPrice(
              displayPosition,
              positionConfigMap[
                joinSymbol(
                  position.long ? 'long' : 'short',
                  position.indexToken
                )
              ],
              prices[position.collateralToken],
              consts.coins[position.collateralToken].decimals
            ),
            2
          );

          return (
            <div className="position-item" key={displayPosition.id}>
              <div className="position-header flex items-center justify-between">
                <div className="flex items-center">
                  <span className="position-symbol-image">
                    <img
                      src={tokenIcons[displayPosition.indexToken]}
                      alt=""
                      className="symbol-image-left"
                    />
                    <img src={UsdcSvg} alt="" className="symbol-image-right" />
                  </span>
                  <span className="position-symbol-name">
                    {localSymbolToOriginal(displayPosition.indexToken)}/USD
                  </span>
                </div>
                <div
                  className="position-symbol-leverage"
                  style={{
                    backgroundColor: displayPosition.long
                      ? LONG_COLOR
                      : SHORT_COLOR,
                  }}
                >
                  {formatNumber(leverage, 1)}x
                </div>
              </div>
              <div className="position-content">
                <div className="position-content-item">
                  <div>
                    <span className="table-title">Unrealized PNL</span>
                    <span
                      style={{
                        color:
                          pnlInCollateralPercentage < 0
                            ? SHORT_COLOR
                            : LONG_COLOR,
                      }}
                    >
                      {pnlInCollateralPercentage < 0 ? '-' : '+'}
                      {formatNumber(
                        Math.abs(pnlInCollateralPercentage * 100),
                        2
                      )}
                      %
                    </span>
                  </div>
                  <div
                    style={{ color: pnlValue < 0 ? SHORT_COLOR : LONG_COLOR }}
                  >
                    {pnlValue < 0 ? '-' : '+'}$
                    {formatNumber(Math.abs(pnlValue), 2)}
                  </div>
                </div>
                <div className="position-content-item">
                  <div className="table-title">Size</div>
                  <div className="table-content">
                    {formatNumber(displayPosition.positionSize, 2)} USD
                  </div>
                </div>
                <div className="position-content-item text-right">
                  <div className="table-title">Collateral</div>
                  <div className="table-content">
                    {formatNumber(
                      displayPosition.collateralAmount /
                        10 ** consts.coins[position.collateralToken].decimals,
                      4
                    )}{' '}
                    {localSymbolToOriginal(position.collateralToken)}
                  </div>
                </div>
                <div className="position-content-item">
                  <div className="table-title">Entry Price</div>
                  <div className="table-content">
                    {priceToString(entryPrice)}
                  </div>
                </div>
                <div className="position-content-item">
                  <div className="table-title">Mark Price</div>
                  <div className="table-content">
                    {priceToString(prices[position.indexToken])}
                  </div>
                </div>
                <div className="position-content-item text-right">
                  <div className="table-title">Est.liq.Price</div>
                  <div className="table-content">
                    <img src={isStable ? StableSvg : WaveSvg} alt="" />
                    <span className="ml-2">
                      {liqPrice}
                    </span>
                  </div>
                </div>
              </div>
              <div className="position-actions">
                <span onClick={() => onAdjust(displayPosition)}>Adjust</span>
                <span onClick={() => onShare(displayPosition)}>Share</span>
                <span onClick={() => onCancel(displayPosition)}>Close</span>
              </div>
            </div>
          )
        })}
    </div>
  )
}

export default PositionTable
