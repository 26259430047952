import { API } from "abex-ts-sdk"
import { useEffect, useRef, useState } from "react"
import { getProvider } from "../app/utils"

export function useReservingRate(collateralToken: string, network: string, amount: number) {
  const [reservingRateMap, setReservingRateMap] = useState<{[key: string]: number}>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<number>(0);
  const currentParamsRef = useRef({ collateralToken, network, amount });

  useEffect(() => {
    if (!collateralToken) return;

    setIsLoading(true);
    const dataAPI = API.getInstance(network, getProvider(network));

    // Update the current parameters ref on every effect execution
    currentParamsRef.current = { collateralToken, network, amount };

    dataAPI.reservingFeeRate(collateralToken, amount).then((fundingRate: number) => {
      // Only update state if the parameters are still the same
      if (
        currentParamsRef.current.collateralToken === collateralToken &&
        currentParamsRef.current.network === network &&
        currentParamsRef.current.amount === amount
      ) {
        setReservingRateMap(prevMap => ({
          ...prevMap,
          [collateralToken]: fundingRate,
        }));
      }
    }).catch((err) => {
      setError(err.message);
    }).finally(() => {
      setIsLoading(false);
    });
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collateralToken, network, refreshToken, amount]);

  const refresh = () => {
    setRefreshToken(prevToken => prevToken + 1);
  };

  return {
    reservingRateMap,
    isLoading,
    error,
    refresh,
  }
}