import React from 'react';
import "./Footer.css";

import Medium from "../../assets/footer/medium.svg";
import Discord from "../../assets/footer/discord.svg";
import Twitter from "../../assets/footer/twitter.svg";
import Email from "../../assets/footer/email.svg";
import Guide from "../../assets/footer/guide.svg";
import Logo from "../../assets/footer/logo.svg";

type Props = {
  className?: string;
}

const Footer: React.FC<Props> = (props) => {
  return (
    <div className={`app-footer-container ${props.className || ""}`}>
      <div className='app-footer flex-1 flex-row justify-between align-center'>
        <div className='media-link flex-row justify-center align-center'>
          <a href="https://abexprotocol.medium.com/" target="_blank" rel="noopener noreferrer">
            <img src={Medium} alt="Medium" className="app-footer-logo" />
          </a>
          <a href="https://discord.gg/gTbkHAHU8B" target="_blank" rel="noopener noreferrer">
            <img src={Discord} alt="Discord" className="app-footer-logo" />
          </a>
          <a href="https://twitter.com/ABExFinance" target="_blank" rel="noopener noreferrer">
            <img src={Twitter} alt="Twitter" className="app-footer-logo" />
          </a>
          <a href="mailto: abex@abex.fi" target="_blank" rel="noopener noreferrer">
            <img src={Email} alt="Email" className="app-footer-logo" />
          </a>
        </div>
        <div className='guide-link flex-row justify-center align-center'>
          <a href="https://abex-protocol.gitbook.io/abex/" target="_blank" rel="noopener noreferrer">
            <img src={Guide} alt="Guide" className="app-footer-logo" />
            <span>User Guide</span>
          </a>
          <a href="https://drive.google.com/file/d/1e8n1IxEYdJcGxJ2GdGDZSfJYzAMyzq5l/view" target="_blank" rel="noopener noreferrer">
            <img src={Logo} alt="Logo" className="app-footer-logo" />
            <span>Media Kit</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
