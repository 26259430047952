import { useState, useEffect } from 'react';

// Custom hook to detect a query parameter 'referral' in the URL
export const useQueryParam = (param: string): string | undefined => {
  const [value, setValue] = useState<string | undefined>(() => {
    // Check if the value is already stored in local storage
    const storedValue = localStorage.getItem(param);
    return storedValue || undefined;
  });

  useEffect(() => {
    if (value) return
    // Function to get the query parameter value
    const getQueryParamValue = () => {
      // Use URLSearchParams with the current window location search string
      const queryParams = new URLSearchParams(window.location.search);
      // Get the value of the 'param' query parameter
      const paramValue = queryParams.get(param);
      return paramValue;
    };

    // Get the current value of the 'referral' query parameter and set it
    const paramValue = getQueryParamValue();
    setValue((prevValue) => {
      // Store the value in local storage if it is not already set
      if (!prevValue) {
        localStorage.setItem(param, paramValue || '');
      }
      return paramValue || undefined;
    });
  }, [param]); // This hook re-runs if the 'param' changes

  return value;
};
