import React, { useState } from 'react';
import Modal from 'react-modal';
import { IConsts, IOrderInfo } from 'abex-ts-sdk';
import ModalHeader from './ModalHeader';
import AdjustBlack from "../../assets/reduce-black.svg";
import {executeApiCall, formatNumber, localSymbolToOriginal, priceToString } from '../utils';
import Button from './Button';
import { IAccountData } from '../../hooks/useAccountData';
import { WalletContextState } from '@suiet/wallet-kit';

type Props = {
  showOrderCancel: boolean
  setShowOrderCancel: Function
  order: IOrderInfo | null;
  consts: IConsts;
  network: string;
  wallet: WalletContextState;
  refreshAccount: () => void;
  refreshOrder: () => void;
  prices: { [key: string]: number };
}
const OrderCancel = ({
  showOrderCancel,
  setShowOrderCancel,
  order,
  consts,
  network,
  wallet,
  refreshAccount,
  refreshOrder,
  prices,
}:Props) => {
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false)

  if (!order) {
    return null
  }
  return (
    <Modal
    className='app-pos-close-main modal-animation'
    isOpen={showOrderCancel}
    onRequestClose={() => {
      setShowOrderCancel(false)
    }}
    shouldCloseOnOverlayClick={true}
  >
    <div className='app-pos-adjust-card'>
      <ModalHeader
        icon={AdjustBlack}
        text={`${localSymbolToOriginal(order?.indexToken || "")}/USD Long`}
        setShowModal={() => setShowOrderCancel(false)}
      />
      <div className='app-pos-adjust-card-body'>
        <div className='flex justify-between mb-16'>
          <div className='flex flex-col flex-1 color-F9-bg pt-8 pb-8 pl-8 pr-8 rounded-8 order-cancel-border'>
            <div className='flex justify-between mb-8'>
              <span className='color-black-text-5 text-12 font-500'>Size</span>
              <span className='color-black-text text-12 font-700'>USD</span>
            </div>
            <div className='flex justify-end color-black-text-7 text-20 font-700'>{formatNumber(order.indexPrice * parseInt((order.openOrder?.openAmount || order.decreaseOrder?.decreaseAmount || 0).toString()) / (10 ** consts.coins[order.indexToken].decimals))}</div>
          </div>
          {order.openOrder && <div className='flex flex-col flex-1 color-F9-bg ml-16 pt-8 pb-8 pl-8 pr-8 rounded-8 order-cancel-border'>
            <div className='flex justify-between mb-8'>
              <span className='color-black-text-5 text-12 font-500'>Collateral</span>
              <span className='color-black-text text-12 font-700'>{localSymbolToOriginal(order.collateralToken)}</span>
            </div>
            <div className='flex justify-end color-black-text-7 text-20 font-700'>{formatNumber(parseInt(order.openOrder.collateralAmount.toString()) / (10 ** consts.coins[order.collateralToken].decimals))}</div>
          </div>}
        </div>
        <div className='flex mb-16 order-cancel-border rounded-8'>
          <div className='flex flex-col flex-1 color-F9-bg pt-8 pb-8 pl-8 pr-8 rounded-8 text-12 font-500'>
            <div className='flex justify-between mb-8'>
              <span className='color-black-text-5'>Mark Price</span>
              <span className='color-black-text-7'>{priceToString(prices[order.indexToken])}</span>
            </div>
            <div className='flex justify-between'>
              <span className='color-black-text-5'>Price</span>
              <span className='color-black-text-7'>{priceToString(order?.indexPrice)}</span>
            </div>
          </div>
        </div>
        <Button
          buttonStyle={'main'}
          text={'Confirm Cancel'}
          onClick={() => {
            console.log(order)
            setIsSubmitLoading(true);
            executeApiCall(
              async (api) => {
                return api.cancelOrder(
                  order.capId,
                  order.collateralToken,
                  order.indexToken,
                  order.long,
                  order.orderType,
                  order.v11Order,
                );
              },
              (res) => {
                return `Success send tx: ${res?.digest}`;
              },
              (e) => `Error: ${e.message}`,
              wallet,
              network,
              [refreshAccount, refreshOrder],
            ).finally(() => {
              setIsSubmitLoading(false)
              setShowOrderCancel(false)
            });
          }}
          isLoading={isSubmitLoading}
          className='app-pos-adjust-detail-confirm-button'
        />
      </div>
    </div>
  </Modal>
  )
}
export default OrderCancel