import React, { useState } from 'react';
import Modal from 'react-modal';
import "./PositionAdjust.css";
import Tabs from '../components/Tabs'
import { IConsts } from 'abex-ts-sdk';
import AdjustBlack from "../../assets/adjust-black.svg";
import { executeApiCall, formatNumber, getCoins, localSymbolToOriginal } from '../utils';
import TokenInput from './TokenInput';
import { TOKENS } from '../constants';
import { IAccountData } from '../../hooks/useAccountData';
import { WalletContextState } from '@suiet/wallet-kit';
import Button from './Button';
import ModalHeader from './ModalHeader';
import InfoList from './InfoList';
import { DisplayPosition } from '../models';
import { ValueChanger } from './ValueChanger';
import { parse } from 'path';

type Props = {
  showPositionAdjust: boolean;
  setShowPositionAdjust: Function;
  position: DisplayPosition | null;
  consts: IConsts;
  prices: { [key: string]: number };
  accountData: IAccountData;
  network: string;
  wallet: WalletContextState;
  refreshAccount: () => void;
  refreshPosition: () => void;
  refreshVaultInfo: () => void;
  refreshReservingRate: () => void;
};

const PositionAdjust: React.FC<Props> = ({
  showPositionAdjust,
  setShowPositionAdjust,
  position,
  consts,
  prices,
  accountData,
  network,
  wallet,
  refreshAccount,
  refreshPosition,
  refreshVaultInfo,
  refreshReservingRate,
}) => {
  const [increase, setIncrease] = useState<string>("deposit")
  const [amount, setAmount] = useState<number | null>(null)
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false)

  if (!position) {
    return null
  }

  const adjustedAmount = (increase === 'deposit' ? amount : -1 * Math.min(position.collateralAmountDisplay, Math.abs(amount || 0))) || 0
  const newLeverage = amount ? (position.leverage * position.collateralAmountDisplay / (position.collateralAmountDisplay + adjustedAmount)) : 0

  const clearStates = () => {
    setIncrease('deposit')
    setAmount(null)
  }

  const checkVaild = () => {
    if (!amount) {
      return 'Invalid Collateral Amount'
    }
    return false
  }
  const invalid = checkVaild()

  const pledgeCollateral = async () => {
    if (!position) {
      return;
    }
    setIsSubmitLoading(true);
    executeApiCall(
      async (api) => {
        const coins = await getCoins(accountData.address, consts.coins[position.collateralToken].module, network);
        const coinObjects = coins.map((e) => e.objectId);
        return api.pledgeInPosition(
          position.id,
          position.collateralToken,
          position.indexToken,
          (amount || 0) * (10 ** consts.coins[position.collateralToken].decimals),
          coinObjects,
          position.long,
        );
      },
      (res) => {
        clearStates();
        return `Success send tx: ${res?.digest}`;
      },
      (e) => `Error: ${e.message}`,
      wallet,
      network,
      [refreshAccount, refreshPosition, refreshVaultInfo, refreshReservingRate, () => setShowPositionAdjust(false)],
    ).finally(() => {
      setIsSubmitLoading(false);
    });
  }

  const redeemCollateral = async () => {
    if (!position) {
      return;
    }
    setIsSubmitLoading(true);
    executeApiCall(
      async (api) => {
        return api.redeemFromPosition(
          position.id,
          position.collateralToken,
          position.indexToken,
          (amount || 0) * (10 ** consts.coins[position.collateralToken].decimals),
          position.long,
        );
      },
      (res) => {
        clearStates();
        return `Success send tx: ${res?.digest}`;
      },
      (e) => `Error: ${e.message}`,
      wallet,
      network,
      [refreshAccount, refreshPosition, refreshVaultInfo, refreshReservingRate, () => setShowPositionAdjust(false)],
    ).finally(() => {
      setIsSubmitLoading(false);
    });
  }

  const actionDetail = (
    <div className="flex-col app-pos-adjust-detail align-start">
      <Tabs data={['deposit', 'withdraw']} className={'app-pos-adjust-action-tab-item-active'} onChange={(name: string) => {
        setIncrease(name)
      }} />
      <div className="app-pos-adjust-detail-title flex flex-col mt-16 justify-between">
        <div className='flex justify-between'>
          <span className='color-black-text-5'>Collateral</span>
          <span className='font-700'>{localSymbolToOriginal(position?.collateralToken || "")}</span>
        </div>
        <div className='flex justify-end text-20 color-black-text-7 font-700 pt-8'>
          {formatNumber((position?.collateralAmount || 0) / (10 ** consts.coins[position.collateralToken].decimals), 4)}
        </div>
      </div>
      <div className='app-pos-adjust-action-content w-full flex-row justify-between'>
        {/* <div className='app-pos-adjust-action-tab flex-col app-pos-adjust-action-tab-secondary'>
          <div
            className={`app-pos-adjust-action-tab-item app-pos-adjust-action-tab-collateral ${increase ? 'app-pos-adjust-action-tab-item-active' : ''}`}
            onClick={() => {
              setIncrease(true)
            }}
          >deposit</div>
          <div
            className={`app-pos-adjust-action-tab-item app-pos-adjust-action-tab-collateral ${!increase ? 'app-pos-adjust-action-tab-item-active' : ''}`}
            onClick={() => {
              setIncrease(false)
            }}
          >withdraw</div>
        </div> */}
        <TokenInput
          token={TOKENS.find(e => e.symbol === position?.collateralToken) || TOKENS[0]}
          titlePrefix={increase}
          isShowColon={false}
          showMax={false}
          // rightText={'Max'}
          // onMax={() => {setAmount(123)}}
          amount={amount}
          // @ts-ignore
          onChange={(value) => setAmount(parseFloat(value || '0'))}
          tokenEditable={false}
          className='app-pos-adjust-action-input'
          keepIconPlaceholder={false}
        />

      </div>
      <InfoList
        className='mb-24'
        content={[
          ['Size', `${formatNumber(position.positionSize)} USD`],
          [`Collateral (${localSymbolToOriginal(position.collateralToken)})`, <ValueChanger originalValue={formatNumber(position.collateralAmountDisplay, 4)} newValue={amount ? `${formatNumber(position.collateralAmountDisplay + amount * (increase === 'deposit' ? 1 : -1), 4)}` : undefined} />],
          ['Leverage', <ValueChanger originalValue={`${formatNumber(position.leverage, 2)}x`} newValue={amount ? `${formatNumber(newLeverage, 2)}x` : undefined} />],
          // ['PnL', `${formatNumber((position?.collateralAmount || 0) / (10 ** COIN_DECIMALS[position?.collateralToken || ""]), 4)} ${localSymbolToOriginal(position?.collateralToken || "")}`],
          // ['Fees', `${formatNumber((position?.collateralAmount || 0) / (10 ** COIN_DECIMALS[position?.collateralToken || ""]), 4)} ${localSymbolToOriginal(position?.collateralToken || "")}`],
          // ['Receive', `${formatNumber((position?.collateralAmount || 0) / (10 ** COIN_DECIMALS[position?.collateralToken || ""]), 4)} ${localSymbolToOriginal(position?.collateralToken || "")}`],
        ]}
      />
      <Button
        buttonStyle={invalid ? 'disabled' : 'main'}
        text={invalid ? invalid : 'Confirm Adjust'}
        onClick={() => {
          if (invalid) {
            return
          }
          if (increase === 'deposit') {
            pledgeCollateral();
          } else {
            redeemCollateral();
          }
        }}
        isLoading={isSubmitLoading}
        className='app-pos-adjust-detail-confirm-button'
      />
    </div>
  )

  return (
    <Modal
      className='app-pos-close-main modal-animation'
      isOpen={showPositionAdjust}
      onRequestClose={() => {
        clearStates()
        setShowPositionAdjust(false)
      }}
      shouldCloseOnOverlayClick={true}
    >
      <div className='app-pos-adjust-card'>
        <ModalHeader
          icon={AdjustBlack}
          text={`${localSymbolToOriginal(position?.indexToken || "")}/USD Long`}
          setShowModal={() => setShowPositionAdjust(false)}
        />
        <div className='app-pos-adjust-card-body'>
          {actionDetail}
        </div>
      </div>
    </Modal>
  );
};

export default PositionAdjust;
