import React from "react";
import "./PnL.css";
import { formatNumber } from "../utils";

type PnLProps = {
  className?: string;
  amount: number;
  percentage: number;
};

const PnL: React.FC<PnLProps> = ({ className, amount, percentage }) => {
  const color = amount > 0 ? "#0ECA82" : "#FA3C58";
  return (
    <div className={`app-pnl flex-col align-center ${className || ""}`}>
      <div className="app-pnl-amount" style={{ color }}>
        {amount < 0 ? "-" : "+"}${formatNumber(Math.abs(amount), 2)}
      </div>
      <div className="app-pnl-percentage">
        {percentage < 0 ? "-": "+"}{formatNumber(Math.abs(percentage), 2)}%
      </div>
    </div>
  );
};

export default PnL;